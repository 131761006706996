/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { MouseEventHandler } from 'react';

import { Tenant } from '@/client';
import { useTheme } from '@/hooks/useTheme';
import { Avatar } from '@/primitives/Avatar';
import { Text } from '@/primitives/Text';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { tokens } from '@/theme';

import './TenantSwitcherSelectedTenant.css';

export type TenantSwitcherSelectedTenantProps = {
  selectedTenant: Tenant;
  nameVisible: boolean;
  setShowInput: (showInput: boolean) => void;
  toggleNameVisible: MouseEventHandler<HTMLSpanElement>;
};

export function TenantSwitcherSelectedTenant({
  selectedTenant,
  nameVisible,
  setShowInput,
  toggleNameVisible,
}: TenantSwitcherSelectedTenantProps) {
  const theme = useTheme();
  const dark = useMgmtPlaneStateStore((state) => state.darkMode);
  const tenantNameClassName = dark ? 'name-dark' : 'name-light';
  const eyeClassName = dark ? 'eye-dark' : 'eye-light';

  const color = { color: theme.antdComponents?.Menu?.itemColor };

  return (
    <section className="tenant-switcher-selected-tenant">
      <span
        className={`tenant-switcher-selected-tenant-name ${tenantNameClassName}`}
        data-testid={`tenant-option-${selectedTenant.id}`}
      >
        <button type="button" onClick={() => setShowInput(true)}>
          <Avatar
            size="xxs"
            text={selectedTenant!.name[0]}
            color={tokens.color.gold.gold03}
            backgroundColor={tokens.color.text.textSubtle}
            dataTestId="tenant-avatar"
            shape="square"
          />
          <span>
            {nameVisible && (
              <Text data-testid="tenant-name-visible" style={color} ellipsis>
                {selectedTenant?.name}
              </Text>
            )}
            {!nameVisible && (
              <Text data-testid="tenant-name-hidden" style={color} ellipsis>
                {selectedTenant?.name.split('').map(() => '•')}
              </Text>
            )}
          </span>
        </button>
      </span>
      <span className={`tenant-switcher-selected-tenant-eye ${eyeClassName}`}>
        {!nameVisible && <EyeOutlined data-testid="show-tenant-name" style={color} onClick={toggleNameVisible} />}
        {nameVisible && (
          <EyeInvisibleOutlined data-testid="hide-tenant-name" style={color} onClick={toggleNameVisible} />
        )}
      </span>
    </section>
  );
}
