/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { UseQueryResult } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { ApiError } from '@/client';
import { SectionHTTPError } from '@/components/SectionHTTPError';
import { useMessage } from '@/hooks/message';
import { Skeleton } from '@/primitives/Skeleton';

interface QueryWrapperProps<T> {
  queryResult: Pick<UseQueryResult<T>, 'data' | 'isLoading' | 'isFetching' | 'isError' | 'error'>;
  loadingComponent?: React.ReactNode;
  errorComponent?: React.ReactNode;
  errorToast?: string;
  children: (data: T) => React.ReactNode;
}

/**
 * A wrapper component that handles common states and errors for React Query hooks.
 * Provides consistent loading, error, and data states across the application.
 *
 * @template T The type of data returned by the query
 *
 * Features:
 * - Loading state with Skeleton component
 * - HTTP error handling (403, 404, 500)
 * - Type-safe data rendering
 * - ApiError vs unknown error differentiation
 *
 * @example
 * ```tsx
 * function NodeDetails() {
 *   const { data, isLoading, error } = useNodeByIdQuery({ nodeId });
 *   return (
 *     <QueryWrapper queryResult={{ data, isLoading, error }}>
 *       {(response) => (
 *         <div>
 *           <h2>{response.name}</h2>
 *         </div>
 *       )}
 *     </QueryWrapper>
 *   );
 * }
 * ```
 *
 * @param props.queryResult - Subset of React Query result containing data, loading, and error states
 * @param props.children - Render function that receives the query data
 * @returns React element based on query state (loading/error/data)
 */
export function QueryWrapper<T>({
  queryResult: query,
  loadingComponent,
  errorComponent,
  errorToast,
  children,
}: QueryWrapperProps<T>) {
  const [message] = useMessage();
  const { data, isLoading, error } = query;
  const apiError = error instanceof ApiError ? error : undefined;
  const unknownError = error && !(error instanceof ApiError);
  const errorMessageShownRef = useRef(false);

  useEffect(() => {
    const hasError = Boolean(apiError || unknownError);

    // When no error occurs, reset the error message flag
    if (!hasError && errorMessageShownRef.current) {
      errorMessageShownRef.current = false;
    }

    // Only show message if we have an error, a toast message, and haven't shown the message yet
    if (hasError && errorToast && !errorMessageShownRef.current) {
      message.error(errorToast);
      errorMessageShownRef.current = true;
    }
  }, [errorToast, message, apiError, unknownError]);

  if (isLoading) return loadingComponent || <Skeleton loading />;

  if ((unknownError || apiError) && errorToast) return null;

  if ((unknownError || apiError) && errorComponent) return errorComponent;

  if (unknownError) return <SectionHTTPError httpStatus="500" />;

  if (apiError) {
    switch (apiError.status) {
      case 403:
        return <SectionHTTPError httpStatus="403" />;
      case 404:
        return <SectionHTTPError httpStatus="404" />;
      default:
        return <SectionHTTPError httpStatus="500" />;
    }
  }

  if (!data) return <SectionHTTPError httpStatus="404" />;

  return children(data);
}
