/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Form } from 'antd';
import { useState } from 'react';

import { ApiError } from '@/client';
import { validationErrors } from '@/components/Forms/validationErrors';
import { useMessage } from '@/hooks/message';
import { useNewWorkspaceMutation } from '@/hooks/mutation/useNewWorkspaceMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { WorkspaceForm, WorkspaceFormFields } from '@/pages/Workspaces/WorkspaceForm';
import { Button } from '@/primitives/Button';
import { Card } from '@/primitives/Card';
import { getValidationMessage } from '@/utils/errors';

export type NewWorkspaceDialogProps = {
  setNewWorkspaceOpen: (open: boolean) => void;
};

export function NewWorkspaceDialog({ setNewWorkspaceOpen }: NewWorkspaceDialogProps) {
  const { t } = useMimicTranslation('workspaces');
  const [form] = Form.useForm<WorkspaceFormFields>();
  const [message] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const { mutate: createWorkspaceMutation } = useNewWorkspaceMutation({
    onError: (error, workspace) => {
      message.error(t('newWorkspaceCreatedError', workspace));
      if (error instanceof ApiError) {
        const newMessage = getValidationMessage(error, 'name');
        form.setFields(validationErrors<WorkspaceFormFields>({ name: newMessage, badgeColor: '' }));
      }
      setIsLoading(false);
    },
    onSuccess: (_, workspace) => {
      message.success(t('newWorkspaceCreated', workspace));
      setNewWorkspaceOpen(false);
      setIsLoading(false);
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  const onWorkspaceCreate = (workspaceName: string, badgeColor: string) => {
    createWorkspaceMutation({ name: workspaceName, badgeColor });
  };

  const onFormFinish = () => {
    const name = form.getFieldValue('name');
    const badgeColor = form.getFieldValue('badgeColor');
    onWorkspaceCreate(name, badgeColor);
  };

  const okButton = (
    <Button
      loading={isLoading}
      type="primary"
      htmlType="submit"
      dataTestId="add-workspace-submit"
      style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
    >
      {t('addWorkspace.submitButton')}
    </Button>
  );
  return (
    <Card padding="md">
      <WorkspaceForm form={form} loading={isLoading} onFinish={onFormFinish} okButton={okButton} horizontal />
    </Card>
  );
}
