/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ColorPicker as AntDColorPicker, ColorPickerProps as AntDColorPickerProps } from 'antd';

export type ColorPickerProps = {
  onChangeColor: AntDColorPickerProps['onChange'];
  colorHex: AntDColorPickerProps['value'];
  dataTestId?: string;
};

export function ColorPicker({ onChangeColor, colorHex, dataTestId }: ColorPickerProps) {
  return (
    <AntDColorPicker
      format="hex"
      value={colorHex}
      onChange={onChangeColor}
      data-testid={dataTestId}
      style={{ backgroundColor: 'transparent', border: 'none' }}
    />
  );
}
