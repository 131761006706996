/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Modal } from 'antd';
import { useState } from 'react';

import { GetWorkspacesResponse, Node } from '@/client';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useUpdateNodeMutation } from '@/hooks/mutation/useUpdateNodeMutation';
import { usePaginatedWorkspacesQuery } from '@/hooks/query/usePaginatedWorkspacesQuery';
import { WorkspaceBadge } from '@/primitives/WorkspaceBadge';

export type NodeWorkspacePickerProps = {
  node: Node;
};

export function NodeWorkspacePicker({ node }: NodeWorkspacePickerProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const paginatedWorkspaceResult = usePaginatedWorkspacesQuery({ type: 'inMemory' });
  const { mutate } = useUpdateNodeMutation({
    nodeId: node.id,
    onSuccess: () => setIsModalOpen(false),
    onError: () => setIsModalOpen(false),
    onSettled: () => {
      setProcessing(false);
      setSelectedApp(null);
    },
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!selectedApp) return;
    setProcessing(true);
    mutate({ workspaceId: selectedApp });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const defaultBadgeColor = '#cccccc';
  const defaultWorkspaceName = 'Tenant';

  return (
    <>
      <WorkspaceBadge onClick={openModal} badgeColor={node.workspace?.badgeColor || defaultBadgeColor} showTooltip>
        {node.workspace?.name || defaultWorkspaceName}
      </WorkspaceBadge>
      <Modal
        title="Change node workspace"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: selectedApp === null }}
        confirmLoading={processing}
      >
        <div style={{ maxHeight: 300, overflowY: 'auto', marginTop: 15 }}>
          <QueryWrapper<GetWorkspacesResponse> queryResult={paginatedWorkspaceResult}>
            {({ data: workspaces }) => {
              return workspaces.map((app, index) => {
                const currentWorkspace = node.workspace?.id === app.id;
                const selected = selectedApp === app.id;
                const lastItem = index === workspaces.length - 1;

                const selectLabel = selected ? 'Selected' : 'Select';
                const buttonLabel = currentWorkspace ? 'Current' : selectLabel;

                return (
                  <div
                    style={{
                      borderBottom: lastItem ? '' : '1px solid #eee',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '12px 0',
                    }}
                  >
                    <WorkspaceBadge badgeColor={app.badgeColor}>{app.name}</WorkspaceBadge>
                    <button
                      disabled={currentWorkspace}
                      type="button"
                      onClick={() => {
                        setSelectedApp(app.id);
                      }}
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        cursor: currentWorkspace ? 'default' : 'pointer',
                      }}
                    >
                      {buttonLabel}
                    </button>
                  </div>
                );
              });
            }}
          </QueryWrapper>
        </div>
      </Modal>
    </>
  );
}
