/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import * as Sentry from '@sentry/react';
import { MenuProps } from 'antd';
import { useCallback, useState } from 'react';

import { PaginatedTenantsResponse, Tenant, User } from '@/client';
import { TenantSwitcherOption } from '@/components/TenantSwitcher/TenantSwitcherOption';
import { usePaginatedTenantQuery } from '@/hooks/query/usePaginatedTenantQuery';
import { useTenantByIdQuery } from '@/hooks/query/useTenantByIdQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { useShowErrorMessage } from '@/hooks/useShowErrorMessage';
import { useTheme } from '@/hooks/useTheme';
import { Dropdown } from '@/primitives/Dropdown';
import { tokens } from '@/theme';

import { TenantOption } from './TenantOption';

export type TenantSwitcherProps = {
  currentUser?: User;
  selectedTenantID?: string;
  onSelectTenant: (tenant: Tenant) => void;
};

export function TenantSwitcher({ currentUser, selectedTenantID, onSelectTenant }: TenantSwitcherProps) {
  const { t } = useMimicTranslation('tenants');
  const { data, isError, error, updateQueryParams } = usePaginatedTenantQuery({ currentUser, params: {} });
  const {
    data: selectedTenantData,
    isError: isSelectedTenantError,
    error: selectedTenantError,
  } = useTenantByIdQuery({ currentUser, tenantId: selectedTenantID! });

  useShowErrorMessage({ showError: isSelectedTenantError, errorMessage: t('errorFetchingTenant') });
  useShowErrorMessage({ showError: isError, errorMessage: t('errorFetchingTenants') });

  const [open, setOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [showInput, setShowInput] = useState<boolean>(false);
  const theme = useTheme();

  const updateSearchTerm = useCallback((term: string) => {
    const trimmedTerm = term.replace(/^\s+|\s+$/g, '');

    if (trimmedTerm.length >= 3) {
      updateQueryParams({ filters: { name: trimmedTerm } });
      setOpen(true);
    }
    if (trimmedTerm.length < 3) {
      updateQueryParams({ filters: { name: '' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTenant = (tenant: Tenant) => {
    onSelectTenant(tenant);
    setShowInput(false);
    setInputSearch('');
    setOpen(false);
    updateQueryParams({ filters: { name: '' } });
  };

  const handlePressEnter = (term: string) => {
    if (term.toLowerCase() === data?.data[0]?.name.toLowerCase()) {
      handleSelectTenant(data?.data[0]);
      setShowInput(false);
      setOpen(false);
      updateQueryParams({ filters: { name: '' } });
    }
  };

  const tenants = data?.data;
  const selectedTenant = selectedTenantData?.data;

  if (!selectedTenant) return null;

  if (isError) Sentry.captureException(error);
  if (isSelectedTenantError) Sentry.captureException(selectedTenantError);

  if (currentUser?.role !== 'superadmin' || isError) {
    return null;
  }

  const items = dropdownItems(handleSelectTenant, tenants, selectedTenant, data);
  return (
    <Dropdown
      menu={{
        items: open ? items : [],
        selectable: true,
        style: {
          maxWidth: tokens.color.components.menu.sidebarMenuWidth,
          background: theme.tokens.color.components.menu.menuLvl2Bg,
        },
        defaultSelectedKeys: selectedTenant ? [selectedTenant.id] : [],
      }}
      onOpenChange={(newState) => setOpen(newState)}
      open={open}
      dataTestId="tenant-switcher"
    >
      <TenantSwitcherOption
        updateSearchTerm={updateSearchTerm}
        selectedTenant={selectedTenant!}
        showInput={showInput}
        setShowInput={setShowInput}
        handlePressEnter={handlePressEnter}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
      />
    </Dropdown>
  );
}

function dropdownItems(
  onSelectTenant: TenantSwitcherProps['onSelectTenant'],
  tenants?: Tenant[],
  selectedTenant?: Tenant,
  data?: PaginatedTenantsResponse,
): MenuProps['items'] {
  const searchTerm = data?.meta.filters.name?.trim();
  if (!searchTerm) return [];

  return tenants?.slice(0, 7).map((tenant) => ({
    label: <TenantOption selectedTenant={selectedTenant!} tenant={tenant} searchTerm={searchTerm} />,
    key: tenant.id,
    onClick: () => {
      onSelectTenant(tenant);
    },
  }));
}
