/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PaginationParams } from '@/client/mgmtPlane';
import { TypeMap, defineTypes, getUrlFilters, setUrlFilters } from '@/utils/filters';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  getUrlPaginationParams,
  setUrlPaginationParams,
} from '@/utils/pagination';
import { Sorter, UpdateParams } from '@/utils/params';
import { getUrlSort, setUrlSortParams } from '@/utils/sort';

const defaultTypeMap = defineTypes({});

/**
 * This hook is used to manage/persist API query params as URL query parameters. Use this hook if you want to persist query params in the URL.
 * If you don't care about persisting query params in the URL, use useInMemoryQueryParams hook.
 * Note: Persisting query params in the URL means that, if users refresh the page, the query params will be preserved.
 * @param defaultFilters - Initial filter values to populate the query params state. If not provided, defaults to an empty object.
 * @returns An object containing:
 *   - filters: Current filter values
 *   - sort: Current sort configuration
 *   - paginationParams: Current pagination state (page and pageSize)
 *   - updateQueryParams: Function to update filters, sort, and pagination values
 */

export function useQueryParams<SortType, FilterType = object, S = Array<string>>(
  types?: TypeMap<FilterType>,
  scope?: string,
  defaultFilters?: FilterType,
  defaultSort?: S,
  defaultPagination?: PaginationParams,
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(
    mergePaginationParams(getUrlPaginationParams(searchParams, scope), defaultPagination),
  );
  const [sort, setSort] = useState<S>(mergeSortParams(getUrlSort<S>(searchParams, scope), defaultSort));

  const typeMap = types || (defaultTypeMap as TypeMap<FilterType>);
  const [filters, setFilters] = useState<FilterType>(
    mergeFilterParams(getUrlFilters(searchParams, typeMap, scope), defaultFilters) as FilterType,
  );

  const [lastFilterApplied, setLastFilterApplied] = useState<string | undefined>(undefined);

  const updateFilters = (newFilters: Partial<FilterType>) => {
    setUrlFilters(searchParams, newFilters, scope);
    setSearchParams(searchParams);
    setFilters(getUrlFilters(searchParams, typeMap, scope));
  };

  const updatePagination = (params: PaginationParams) => {
    setUrlPaginationParams(searchParams, params, scope);
    setSearchParams(searchParams);
    setPaginationParams(getUrlPaginationParams(searchParams, scope));
  };

  const updateSort = (newSort: Sorter<SortType>[]) => {
    setUrlSortParams(searchParams, newSort, scope);
    setSearchParams(searchParams);
    setSort(getUrlSort<S>(searchParams, scope));
  };

  const updateQueryParams: UpdateParams<SortType, FilterType> = ({ filters: f, sort: s, paginationParams: p }) => {
    const requestedFiltersHash = JSON.stringify(f);
    if (f && requestedFiltersHash !== lastFilterApplied) {
      setLastFilterApplied(requestedFiltersHash);
      updateFilters(f);
    }
    if (p) {
      updatePagination(p);
    }
    if (s) {
      updateSort(s);
    }
  };

  if (paginationParams.number !== undefined && paginationParams.number < 1) {
    paginationParams.number = DEFAULT_PAGE_NUMBER;
    updatePagination(paginationParams);
  }
  if (paginationParams.size !== undefined && paginationParams.size < 0) {
    paginationParams.size = DEFAULT_PAGE_SIZE;
    updatePagination(paginationParams);
  }

  return {
    filters,
    sort,
    paginationParams,
    updateQueryParams,
  };
}

function mergePaginationParams(
  paginationParams: PaginationParams,
  defaultPagination?: PaginationParams,
): PaginationParams {
  return {
    number: paginationParams.number || defaultPagination?.number || DEFAULT_PAGE_NUMBER,
    size: paginationParams.size || defaultPagination?.size || DEFAULT_PAGE_SIZE,
  };
}

function mergeFilterParams<T>(filterParams: T, defaultFilters?: T): T {
  return filterParams || defaultFilters || ({} as T);
}

function mergeSortParams<S>(sortParams: S, defaultSort?: S): S {
  if (Array.isArray(sortParams) && sortParams.length > 0) {
    return sortParams;
  }
  if (Array.isArray(defaultSort) && defaultSort.length > 0) {
    return defaultSort;
  }
  return [] as S;
}
