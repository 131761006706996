/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, DeleteWorkspaceResponse, Workspace } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type DeleteWorkspaceMutationConfig = {
  onSuccess?: (data: void, variables: Workspace) => void;
  onError?: (error: unknown, variables: Workspace) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: Workspace) => Promise<unknown> | void;
};

/**
 * Hook for deleting workspace by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful workspace deletion
 * @param config.onError - Called if workspace deletion fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for deleting workspace
 *
 * @example
 * ```tsx
 * const { mutate } = useDeleteWorkspaceMutation({
 *   onSuccess: (data) => console.log('Workspace Deleted:', data),
 *   onError: (error) => console.error('Workspace Deletion failed:', error)
 * });
 *
 * // Delete Workspace
 * mutate({ workspaceId: '9fb763f7-33de-4969-a7a0-b808f4637318' });
 * ```
 */

export function useDeleteWorkspaceMutation({ onSuccess, onError, onSettled, onMutate }: DeleteWorkspaceMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<DeleteWorkspaceResponse, ApiError, Workspace>({
    mutationFn: (workspace: Workspace) => {
      return mgmtPlaneAPIClient.workspaces.deleteWorkspace({ tenantId: tenantId!, id: workspace.id });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
