/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Modal } from 'antd';

import { ApiToken } from '@/client';
import { useMessage } from '@/hooks/message';
import { useRevokeAPITokenMutation } from '@/hooks/mutation/useRevokeAPITokenMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';

type RevokeAPITokenModalProps = {
  apiToken: ApiToken | undefined;
  onCancelAPITokenRevocation: () => void;
};

export function RevokeAPITokenModal({
  apiToken,
  onCancelAPITokenRevocation: onCancelAPITokenDelete,
}: RevokeAPITokenModalProps) {
  const { t } = useMimicTranslation('apiTokens');

  const [message] = useMessage();

  const { mutate: revokeAPIToken, isPending } = useRevokeAPITokenMutation({
    onError: () => {
      message.error(t('revocationError'));
    },
    onSuccess: () => {
      message.success(t('tokenRevoked'));
      closeDeleteDialog();
    },
  });

  const onAPITokenDelete = () => {
    revokeAPIToken(apiToken!);
  };

  const closeDeleteDialog = () => {
    onCancelAPITokenDelete();
  };

  return (
    <Modal
      open={Boolean(apiToken)}
      onCancel={onCancelAPITokenDelete}
      onOk={onAPITokenDelete}
      cancelText={t('confirmDelete.cancel')}
      cancelButtonProps={{ disabled: isPending }}
      okText={t('confirmDelete.ok')}
      okButtonProps={{ danger: true, loading: isPending }}
    >
      <Container padding="sm">
        <Title level={4}>{t('confirmDelete.title', { name: apiToken?.name })}</Title>
        <Text>{t('confirmDelete.body1')}</Text>
        <Text>{t('confirmDelete.body2')}</Text>
      </Container>
    </Modal>
  );
}
