/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import { OPENAPI_RESOURCE_ID, OPENAPI_TENANT_ID, USER_INFO } from '../../types';

export const apiTokenName = z
  .string()
  .min(1)
  .max(30)
  .openapi({ example: 'CI pipeline' });

// query schemas
export const ApiTokenFilters = z
  .object({
    name: z.string().optional(),
    revoked: z.boolean().optional(),
  })
  .openapi('ApiTokenFilters');

export const ApiTokenSortableFields = sortable('ApiTokenSortableFields', [
  'name',
  'dateCreated',
]);

export const ApiTokenQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: ApiTokenFilters.optional(),
    sort: ApiTokenSortableFields.optional(),
  }),
);

// mutation schemas
export const CreateApiTokenSchema = z
  .object({
    name: apiTokenName,
  })
  .openapi('CreateToken');

// response schemas
const ApiTokenResponse = z
  .object({
    id: OPENAPI_TENANT_ID,
    name: apiTokenName,
    tenantId: OPENAPI_RESOURCE_ID,
    createdBy: USER_INFO,
    dateCreated: z.date().openapi({ format: 'date-time' }),
    revokedBy: USER_INFO.optional(),
    dateRevoked: z.date().optional().openapi({ format: 'date-time' }),
  })
  .openapi('ApiToken');

export const ApiTokenResponseSchema = buildResponseSchema(
  'ApiTokenResponse',
  ApiTokenResponse,
);

export const CreateApiTokenResponseSchema = buildResponseSchema(
  'CreateApiTokenResponse',
  ApiTokenResponse.merge(
    z.object({
      token: z
        .string()
        .openapi({ example: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9' }),
    }),
  ).openapi('CreateApiTokenData'),
);

export const RevokeApiTokenResponseSchema = buildResponseSchema(
  'RevokeApiTokenResponse',
  ApiTokenResponse.pick({
    id: true,
    name: true,
  }).openapi('RevokeApiTokenData'),
);

export const PaginatedApiTokensResponseSchema = buildPaginatedResponseSchema(
  'PaginatedApiTokensResponse',
  ApiTokenResponse,
  ApiTokenFilters,
  ApiTokenSortableFields,
);
