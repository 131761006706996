/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { HTTPError } from '@/components/HTTPError';
import { WorkspaceDetailsPage } from '@/pages/WorkspaceDetailsPage/WorkspaceDetailsPage';

export function WorkspaceDetailsRoute() {
  const { id: workspaceId } = useParams<'id'>();

  if (!workspaceId || !uuidValidate(workspaceId)) {
    return <HTTPError httpStatus="404" />;
  }

  return <WorkspaceDetailsPage workspaceId={workspaceId} />;
}
