/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { CopyOutlined, KeyOutlined } from '@ant-design/icons';
import { Form, Modal, Space } from 'antd';
import { useState } from 'react';

import { ApiError, CreateApiTokenResponse, CreateToken } from '@/client';
import { ValidationError } from '@/components/Forms/ValidationError';
import { validationErrors } from '@/components/Forms/validationErrors';
import { useMessage } from '@/hooks/message';
import { useNewAPITokenMutation } from '@/hooks/mutation/useNewAPITokenMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Container } from '@/primitives/Container';
import { Input } from '@/primitives/Input';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { getValidationMessage } from '@/utils/errors';
import './CreateAPITokenModal.css';

type CreateAPITokenModalProps = {
  onCancelAPITokenCreation: () => void;
  copyToClipboard: (text: string) => void;
};

const maxAPITokenLength = 30;

export function CreateAPITokenModal({ onCancelAPITokenCreation, copyToClipboard }: CreateAPITokenModalProps) {
  const { t } = useMimicTranslation('apiTokens');
  const { t: globalT } = useMimicTranslation('global');
  const [form] = Form.useForm<CreateToken>();
  const [createdToken, setCreatedToken] = useState<CreateApiTokenResponse | undefined>();

  const [message] = useMessage();

  const { mutate: createAPIToken, isPending } = useNewAPITokenMutation({
    onError: (error) => {
      message.error(t('creationError'));
      if (error instanceof ApiError) {
        const newMessage = getValidationMessage(error, 'name');
        form.setFields(validationErrors<CreateToken>({ name: newMessage }));
      }
    },
    onSuccess: (apiToken) => {
      message.success(t('apiTokenCreated'));
      setCreatedToken(apiToken);
    },
  });

  const onAPITokenCreate = () => {
    form
      .validateFields()
      .then(() => {
        createAPIToken({
          name: form.getFieldValue('name').trim(),
        });
      })
      .catch(() => {
        // no op: errors are shown by the form
      });
  };

  const closeCreationDialog = () => {
    onCancelAPITokenCreation();
  };

  return (
    <Modal
      open
      className="create-api-token-modal"
      onCancel={closeCreationDialog}
      onOk={createdToken ? closeCreationDialog : onAPITokenCreate}
      cancelText={t('createTokenModal.cancel')}
      cancelButtonProps={{ disabled: isPending }}
      okText={createdToken ? t('createTokenModal.ok') : t('createTokenModal.create')}
      okButtonProps={{ danger: false, loading: isPending }}
    >
      <Container padding="sm">
        <Title level={4} data-testid="created-token-title">
          {!createdToken && t('createTokenModal.title')}
          {!!createdToken && createdToken.data.name}
        </Title>
        {!createdToken && (
          <Form form={form} autoComplete="off" layout="vertical" style={{ width: '100%' }}>
            <Container gap="xs" style={{ width: '100%' }}>
              <Form.Item<CreateToken>
                status="error"
                name="name"
                style={{ width: '100%', marginBottom: 0 }}
                rules={[
                  { required: true, message: <ValidationError>{t('createTokenModal.nameRequired')}</ValidationError> },
                  {
                    max: maxAPITokenLength,
                    message: (
                      <ValidationError>
                        {t('createTokenModal.nameMaxLength', { maxLength: maxAPITokenLength })}
                      </ValidationError>
                    ),
                  },
                ]}
              >
                <Input
                  disabled={isPending}
                  autoFocus
                  prefix={<KeyOutlined />}
                  placeholder={t('createTokenModal.namePlaceholder')}
                  onChange={(event) => {
                    form.setFieldValue('name', event.target.value);
                  }}
                  allowClear
                  dataTestId="api-token-name-input"
                  count={{
                    max: maxAPITokenLength,
                  }}
                />
              </Form.Item>
            </Container>
          </Form>
        )}
        {createdToken && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%' }}>
            <Space.Compact>
              <Input readOnly value={createdToken?.data.token} dataTestId="token-field" />
              <Button type="default" onClick={() => copyToClipboard(createdToken?.data.token)} dataTestId="copy-button">
                <CopyOutlined /> {globalT('copy')}
              </Button>
            </Space.Compact>
            <Text type="error">
              <strong>{t('copyTokenWarningNote')}</strong> {t('copyTokenWarning')}
            </Text>
          </div>
        )}
      </Container>
    </Modal>
  );
}
