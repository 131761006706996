/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Navigate } from 'react-router-dom';

export type CallbackRouteProps = {
  selectedTenantID: string;
  replace?: boolean;
};

export function CallbackRoute({ selectedTenantID, replace }: CallbackRouteProps) {
  const returnTo = sessionStorage.getItem('returnTo');
  const to = returnTo === '/' ? undefined : returnTo;
  if (to) {
    return <Navigate to={to} replace={replace} />;
  }

  return <Navigate to={`/tenants/${selectedTenantID}/activity`} replace={replace} />;
}
