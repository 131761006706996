/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  buildPaginatedResponseSchema,
  buildResponseSchema,
  PaginationParams,
  sortable,
} from '../../openapi';
import {
  NODE_OPERATING_SYSTEM,
  OPENAPI_NODE_ID,
  OPENAPI_TENANT_ID,
  TYPE_UUID,
  USER_INFO,
} from '../../types';
import { NodeHostname, NodeVersion } from '../nodes/schemas';
import { WorkspaceSchema } from '../workspaces/schemas';

// base schema
export const JobStatus = z
  .enum(['running', 'success', 'warning', 'error', 'timeout'])
  .openapi('JobStatus', { example: 'success' });

export const BaseJobSchema = z.object({
  id: TYPE_UUID.openapi({
    'x-go-name': 'ID',
    example: '550e8400-e29b-41d4-a716-446655440000',
  }),
  tenantId: OPENAPI_TENANT_ID,
  workspace: WorkspaceSchema.optional(),
  name: z
    .string()
    .openapi({ example: 'Configure 8 nodes-v0.0.12_dev, apac, sales' }),
  targetConfig: z.object({
    configRevision: z.object({
      id: TYPE_UUID,
      revisionNumber: z.number().int(),
      configSchema: z.string().openapi({ example: 'v1.0.0' }),
      filename: z.string().openapi({ example: 'public-api-mimic.cue' }),
      description: z.string(),
      createdBy: USER_INFO,
    }),
    nodeConfig: z.object({
      id: TYPE_UUID,
      name: z.string(),
      latestRevisionNumber: z.number().int(),
      operatingSystem: NODE_OPERATING_SYSTEM,
    }),
  }),
  status: JobStatus,
  nodeStatus: z.object({
    running: z.number().int().openapi({ example: 4 }),
    success: z.number().int().openapi({ example: 3 }),
    warning: z.number().int().openapi({ example: 2 }),
    error: z.number().int().openapi({ example: 1 }),
    timeout: z.number().int().openapi({ example: 3 }),
  }),
  dateCreated: z.date().openapi({ format: 'date-time' }),
  dateCompleted: z.date().optional().openapi({ format: 'date-time' }),
  createdBy: USER_INFO,
  notificationDismissed: z.boolean().optional().openapi({ example: false }),
});

export const BaseJobNodeSchema = z.object({
  id: TYPE_UUID.openapi({
    'x-go-name': 'ID',
    example: '550e8400-e29b-41d4-a716-446655440000',
  }),
  nodeId: OPENAPI_NODE_ID,
  hostname: NodeHostname,
  nodeVersion: NodeVersion,
  operatingSystem: NODE_OPERATING_SYSTEM,
  status: JobStatus,
  statusDescription: z
    .string()
    .optional()
    .openapi({ example: 'Node timed out' }),
  dateCompleted: z.date().optional().openapi({ format: 'date-time' }),
  warnings: z
    .any()
    .optional()
    .openapi({
      example: [{ message: 'Warning message' }, 'Simple warning string'],
    }),
  errors: z
    .any()
    .optional()
    .openapi({
      example: [{ message: 'Error message' }, 'Simple error string'],
    }),
});

export const JobSchema = BaseJobSchema.openapi('Job');
export const JobNodeSchema = BaseJobNodeSchema.openapi('JobNode');

// query schemas
export const JobFilterParamsSchema = z
  .object({
    name: z.string().optional(),
    targetConfig: z.string().optional(),
    operatingSystem: z
      .string()
      .optional()
      .openapi({ example: 'linux,windows' }),
    notificationDismissed: z.boolean().optional(),
    nodeConfigId: TYPE_UUID.optional(),
    status: z.string().optional().openapi({ example: 'error,warning,timeout' }),
  })
  .openapi('JobFilters');

export const JobSortableFields = sortable('JobSortableFields', [
  'name',
  'targetConfig',
  'jobNumber',
  'dateCreated',
  'creatorName',
]);

export const JobQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: JobFilterParamsSchema.optional(),
    sort: JobSortableFields.optional(),
  }),
);

export const JobNodeFilterParamsSchema = z.object({}).openapi('JobNodeFilters');
export const JobNodeSortableFields = sortable('JobNodeSortableFields', [
  'id',
  'hostname',
  'nodeVersion',
  'operatingSystem',
  'status',
]);

export const JobNodeQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: JobNodeFilterParamsSchema.optional(),
    sort: JobNodeSortableFields.optional(),
  }),
);

// mutation schemas
export const UpdateJobSchema = z
  .object({
    notificationDismissed: z.boolean().openapi({ example: true }),
  })
  .openapi('UpdateJob');

// response schemas
export const JobResponseSchema = buildResponseSchema('JobResponse', JobSchema);

export const PaginatedJobsResponseSchema = buildPaginatedResponseSchema(
  'PaginatedJobsResponse',
  JobSchema,
  JobFilterParamsSchema,
  JobSortableFields,
);

export const PaginatedJobNodesResponseSchema = buildPaginatedResponseSchema(
  'PaginatedJobNodesResponse',
  JobNodeSchema,
  JobNodeFilterParamsSchema,
  JobNodeSortableFields,
);
