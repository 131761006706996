/*
 * Copyright Mimic Networks, Inc. 2025.
 */
import { TenantFiltersParamsSchema } from 'openapi-spec/api/routes/tenants/schemas';

import { GetTenantsData, GetTenantsResponse, Tenant, TenantFilters, TenantSortableFields, User } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedTenantsQueryParams = {
  currentUser?: User;
  params: GetTenantsData;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated tenants with filtering and sorting.
 * Utilizes React Query for data fetching.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated tenants response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 */
export function usePaginatedTenantQuery({ params, type, currentUser }: UsePaginatedTenantsQueryParams) {
  return usePaginatedQuery<GetTenantsResponse, Tenant, TenantFilters, TenantSortableFields, GetTenantsData>({
    apiCall: mgmtPlaneAPIClient.tenants.getTenants.bind(mgmtPlaneAPIClient.tenants),
    filterTypes: zodSchemaToTypeMap(TenantFiltersParamsSchema.shape),
    queryKey: 'tenants',
    realtimeEvents: [],
    params,
    type,
    enabled: currentUser?.role === 'superadmin',
  });
}
