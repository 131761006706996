/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { SetStateAction } from 'react';

import { Workspace, WorkspacesFilter, PaginatedWorkspacesResponse } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { useWorkspaceListColumns } from '@/pages/Workspaces/useWorkspaceListColumns';
import { UpdateParams } from '@/utils/params';

export type WorkspacesListPageProps = {
  workspacesData: PaginatedWorkspacesResponse;
  onParamsChange: UpdateParams<Workspace, WorkspacesFilter>;
  editWorkspace: (workspace: Workspace) => void;
  setDeleteWorkspace: React.Dispatch<SetStateAction<Workspace | undefined>>;
};

export function WorkspacesList({
  workspacesData,
  onParamsChange,
  editWorkspace,
  setDeleteWorkspace,
}: WorkspacesListPageProps) {
  const searchText = workspacesData.meta.filters.name || '';
  const columns = useWorkspaceListColumns(searchText, editWorkspace, setDeleteWorkspace);

  return (
    <PaginatedTable
      data={workspacesData}
      rowKey={(workspace) => workspace.id}
      columns={columns}
      onParamsChange={onParamsChange}
    />
  );
}
