/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import { createContext } from 'react';

import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { antdComponentConfig, antdTokens, tokens } from '@/theme';

export type ThemeProps = { children: React.ReactNode };

const px2rem = px2remTransformer({
  rootValue: 16,
});

export const ThemeProviderContext = createContext({ antdTokens, antdComponents: antdComponentConfig, tokens });

export function Theme({ children }: ThemeProps) {
  const theme = useMgmtPlaneStateStore((state) => state.theme);

  return (
    <ThemeProviderContext.Provider value={theme}>
      <ConfigProvider
        theme={{
          token: theme.antdTokens,
          components: theme.antdComponents,
        }}
      >
        <StyleProvider transformers={[px2rem]}>{children}</StyleProvider>
      </ConfigProvider>
    </ThemeProviderContext.Provider>
  );
}
