/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { PresetStatusColorType } from 'antd/es/_util/colors';
import { ThemeConfig } from 'antd/es/config-provider/context';
import type { AliasToken } from 'antd/es/theme/interface';

// Begins section generated from Figma token export
const color = {
  sky: {
    sky01: '#ffffff',
    sky02: '#fbfbfb',
    sky03: '#f4f4f4',
    sky04: '#efefee',
    sky05: '#e5e5e4',
    sky06: '#dbdad9',
    sky07: '#cecdcc',
    sky08: '#c5c4c2',
    sky09: '#b9b7b5',
    sky10: '#afadab',
  },
  ink: {
    ink01: '#979592',
    ink02: '#888684',
    ink03: '#757472',
    ink04: '#686765',
    ink05: '#565554',
    ink06: '#464544',
    ink07: '#393837',
    ink08: '#292828',
    ink09: '#222121',
    ink10: '#191919',
  },
  gold: {
    gold01: '#faefd1',
    gold02: '#f3dfaa',
    gold03: '#f4cf71',
    gold04: '#d4b25a',
    gold05: '#bfa050',
    gold06: '#a08644',
    gold07: '#826d37',
    gold08: '#63532b',
    gold09: '#403929',
    gold10: '#373229',
    goldAlternate: '#c2a978',
  },
  purple: {
    purple01: '#f0ecf4',
    purple02: '#e4dbeb',
    purple03: '#d5bfe8',
    purple04: '#c3a4e5',
    purple05: '#ac8ed7',
    purple06: '#8d6fbe',
    purple07: '#7150a8',
    purple08: '#483374',
    purple09: '#3c2f56',
    purple10: '#342c44',
  },
  blue: {
    blue01: '#e4f7fc',
    blue02: '#cbe9f0',
    blue03: '#8bd4e8',
    blue04: '#60bddb',
    blue05: '#3aa7cf',
    blue06: '#178bc1',
    blue07: '#126d97',
    blue08: '#08527d',
    blue09: '#1c3b49',
    blue10: '#20323a',
  },
  green: {
    green01: '#ecf4e0',
    green02: '#d1e2bb',
    green03: '#b6d68d',
    green04: '#9ac168',
    green05: '#7da749',
    green06: '#5c911c',
    green07: '#4a7119',
    green08: '#344b15',
    green09: '#2e391e',
    green10: '#2c3222',
  },
  red: {
    red01: '#fce7e3',
    red02: '#fad6cf',
    red03: '#ffc4b8',
    red04: '#ff9a78',
    red05: '#ff7142',
    red06: '#de4b1c',
    red07: '#a13517',
    red08: '#692317',
    red09: '#49261f',
    red010: '#402622',
  },
  orange: {
    orange01: '#ffebd6',
    orange02: '#ffdcb8',
    orange03: '#ffc68a',
    orange04: '#ffac5a',
    orange05: '#ff9a2e',
    orange06: '#ff8800',
    orange07: '#c25700',
    orange08: '#823b00',
    orange09: '#512500',
    orange10: '#411a01',
  },
  yellow: {
    yellow01: '#faf6cf',
    yellow02: '#f3e9b4',
    yellow03: '#f9dc7d',
    yellow04: '#fac83d',
    yellow05: '#dda428',
    yellow06: '#b68107',
    yellow07: '#966703',
    yellow08: '#6e4602',
    yellow09: '#473517',
    yellow10: '#3b301e',
  },
  cyan: {
    cyan01: '#e6fffb',
    cyan02: '#b5f5ec',
    cyan03: '#87e8de',
    cyan04: '#5cdbd3',
    cyan05: '#36cfc9',
    cyan06: '#13c2c2',
    cyan07: '#08979c',
    cyan08: '#006d75',
    cyan09: '#00474f',
    cyan10: '#002329',
  },
  magenta: {
    magenta01: '#fff0f6',
    magenta02: '#ffd6e7',
    magenta03: '#ffadd2',
    magenta04: '#ff85c0',
    magenta05: '#f759ab',
    magenta06: '#eb2f96',
    magenta07: '#c41d7f',
    magenta08: '#9e1068',
    magenta09: '#780650',
    magenta10: '#520339',
  },
  volcano: {
    volcano01: '#fff2e8',
    volcano02: '#ffd8bf',
    volcano03: '#ffbb96',
    volcano04: '#ff9c6e',
    volcano05: '#ff7a45',
    volcano06: '#fa541c',
    volcano07: '#d4380d',
    volcano08: '#ad2102',
    volcano09: '#871400',
    volcano10: '#610b00',
  },
  geekblue: {
    geekblue01: '#f0f5ff',
    geekblue02: '#d6e4ff',
    geekblue03: '#adc6ff',
    geekblue04: '#85a5ff',
    geekblue05: '#597ef7',
    geekblue06: '#2f54eb',
    geekblue07: '#1d39c4',
    geekblue08: '#10239e',
    geekblue09: '#061178',
    geekblue10: '#030852',
  },
  lime: {
    lime01: '#fcffe6',
    lime02: '#f4ffb8',
    lime03: '#eaff8f',
    lime04: '#d3f261',
    lime05: '#bae637',
    lime06: '#a0d911',
    lime07: '#7cb305',
    lime08: '#5b8c00',
    lime09: '#3f6600',
    lime10: '#254000',
  },
};
const size = {
  size000: 0,
  size025: 2,
  size050: 4,
  size075: 6,
  size100: 8,
  size150: 12,
  size200: 16,
  size250: 20,
  size300: 24,
  size400: 32,
  size500: 40,
  size600: 48,
  size700: 56,
  size800: 64,
  size900: 72,
  size1000: 80,
};
const colorText = color.ink.ink09;
const colorTextSecondary = 'rgba(25, 25, 25, 0.7900)';
const colorTextTertiary = 'rgba(25, 25, 25, 0.6000)';
const colorTextQuaternary = 'rgba(25, 25, 25, 0.2000)';
const colorTextInverse = color.sky.sky02;
const colorTextInverseSecondary = 'rgba(251, 251, 251, 0.8500)';
// const colorTextInverseTertiary = "rgba(251, 251, 251, 0.7000)";
const colorTextInverseQuaternary = 'rgba(251, 251, 251, 0.3000)';
const colorBgContainer = color.sky.sky03;
const colorBgElevated = color.sky.sky01;
const colorBgLayout = color.sky.sky02;
const colorBgLayoutDark = '#ffffff';
const colorBgMask = 'rgba(25, 25, 25, 0.4000)';
const colorBgSpotlight = color.ink.ink09;
const colorFillDefault = 'rgba(25, 25, 25, 0.3300)';
const colorFillSecondary = 'rgba(25, 25, 25, 0.1000)';
const colorFillTertiary = 'rgba(25, 25, 25, 0.0600)';
const colorFillQuaternary = 'rgba(25, 25, 25, 0.0400)';
const colorFillInverseDefault = 'rgba(251, 251, 251, 0.5000)';
const colorFillInverseSecondary = 'rgba(251, 251, 251, 0.2000)';
const colorFillInverseTertiary = 'rgba(251, 251, 251, 0.0800)';
// const colorFillInverseQuaternary = "rgba(251, 251, 251, 0.0500)";
const colorFillContent = colorFillTertiary;
const colorFillAlter = colorFillQuaternary;
const colorBorder = color.sky.sky06;
const colorBorderSecondary = color.sky.sky04;
const colorSplit = colorBorderSecondary;
const controlItemBgHover = colorFillSecondary;
const controlItemBgActive = 'rgba(222, 186, 92, 0.6500)';
const controlItemBgActiveSubtle = 'rgba(222, 186, 92, 0.4000)';
const controlItemBgActiveHover = color.gold.gold04;
const surface = {
  surfaceHover: colorFillSecondary,
  surfacePressed: colorFillDefault,
  surfaceHoverSubtle: colorFillTertiary,
  surfacePressedSubtle: colorFillSecondary,
  surfaceInverseHover: colorFillInverseSecondary,
  surfaceInversePressed: colorFillInverseDefault,
  surfaceInverseHoverSubtle: colorFillInverseTertiary,
  surfaceInversePressedSubtle: colorFillInverseSecondary,
  surfaceHigh: colorBgElevated,
  surfaceDefault: colorBgLayout,
  surfaceLow: colorBgContainer,
  surfaceLower: color.sky.sky04,
  surfaceLowest: color.sky.sky05,
  surfaceDeep: color.sky.sky07,
  surfaceDisabled: colorFillQuaternary,
  surfaceInverse: colorBgSpotlight,
  surfaceScrim: colorBgMask,
};
const link = {
  linkDefault: color.blue.blue08,
  linkHover: color.blue.blue06,
  linkActive: color.blue.blue09,
};
const text = {
  textDefault: colorText,
  textSubtle: colorTextSecondary,
  textPlaceholder: colorTextTertiary,
  textDisabled: colorTextQuaternary,
  textInverse: colorTextInverse,
  textInverseSubtle: colorTextInverseSecondary,
  textInverseDisabled: colorTextInverseQuaternary,
};
const border = {
  borderDefault: colorFillSecondary,
  borderDisabled: colorFillTertiary,
  borderFaint: colorFillQuaternary,
  borderSubtle: colorFillTertiary,
  borderDeep: colorTextQuaternary,
  borderDeeper: colorFillDefault,
  depreciate: {
    borderSubtleSolid: colorBorderSecondary,
    borderDefaultSolid: colorBorder,
    borderVariantSolid: color.sky.sky08,
    borderDeepSolid: color.sky.sky10,
    borderDeeperSolid: color.ink.ink01,
  },
};
const primary = {
  primaryBlack: color.ink.ink09,
  primaryBlackHover: color.ink.ink04,
  primaryBlackPressed: color.ink.ink10,
  primaryGoldBg: color.gold.gold01,
  primaryGoldSubtle: color.gold.gold03,
  primaryGold: color.gold.gold04,
  primaryGoldVariant: color.gold.gold05,
  primaryGoldVariantHover: color.gold.gold07,
  primaryGoldVariantPressed: color.gold.gold08,
  primaryGoldDeep: color.gold.gold06,
  primaryGoldDeepHover: color.gold.gold07,
  primaryGoldDeepPressed: color.gold.gold09,
  primaryGoldText: color.gold.gold08,
  primaryGoldTextHover: color.gold.gold09,
  primaryGoldTextPressed: color.gold.gold10,
  primaryGoldBgTrans: controlItemBgActive,
  primaryGoldBgTransSubtle: controlItemBgActiveSubtle,
  primaryGoldBgTransHover: controlItemBgActiveHover,
};
const iconActive = color.blue.blue07;
const icon = {
  iconDefault: text.textSubtle,
  iconInverse: text.textInverse,
  iconInverseSubtle: text.textInverseSubtle,
  iconHover: color.ink.ink10,
  iconPressed: primary.primaryGoldVariantPressed,
  iconActive,
  iconSubtleDefault: color.ink.ink02,
  iconSubtleHover: color.ink.ink04,
  iconSubtlePressed: color.ink.ink06,
  iconSubtleHoverVariant: color.blue.blue06,
  iconSubtleHoverPressed: color.blue.blue09,
  iconSubtleActive: iconActive,
  iconPrimary: primary.primaryGoldDeep,
  iconPrimaryHover: primary.primaryGoldDeepHover,
  iconPrimaryPressed: primary.primaryGoldDeepPressed,
};
const neutral = {
  neutralDefault: color.sky.sky10,
  neutralHover: color.ink.ink03,
  neutralPressed: color.ink.ink05,
  neutralSubtle: color.sky.sky07,
  neutralSubtleHover: color.sky.sky09,
  neutralSubtlePressed: color.ink.ink01,
  neutralVariant: color.ink.ink05,
  neutralVariantHover: color.ink.ink03,
  neutralVariantPressed: color.ink.ink07,
};
const infoHover = color.blue.blue08;
const infoPressed = color.blue.blue09;
const infoBg = color.blue.blue01;
const info = {
  infoDefault: color.blue.blue05,
  infoHover,
  infoPressed,
  infoVariant: color.blue.blue06,
  infoVariantHover: infoHover,
  infoVariantPressed: infoPressed,
  infoText: color.blue.blue08,
  infoTextHover: color.blue.blue06,
  infoTextPressed: infoPressed,
  infoBorder: color.blue.blue03,
  infoBorderHover: color.blue.blue05,
  infoBg,
  infoBgHover: infoBg,
};
const successPressed = color.green.green09;
const success = {
  successBg: color.green.green01,
  successBgHover: color.green.green02,
  successBorderDefault: color.green.green03,
  successBorderHover: color.green.green04,
  successDefault: color.green.green06,
  successHover: color.green.green07,
  successPressed,
  successVariant: color.green.green05,
  successVariantHover: color.green.green06,
  successVariantPressed: successPressed,
  successText: color.green.green07,
  successTextHover: color.green.green08,
  successTextPressed: successPressed,
};
const warningPressed = color.yellow.yellow08;
const warningHover = color.yellow.yellow06;
const errorPressed = color.red.red09;
const error = {
  warning: {
    warning: color.yellow.yellow05,
    warningSubtle: color.yellow.yellow04,
    warningHover,
    warningPressed,
    warningVariant: color.yellow.yellow06,
    warningVariantHover: color.yellow.yellow05,
    warningVariantPressed: warningPressed,
    warningTextDefault: color.yellow.yellow08,
    warningTextHover: warningHover,
    warningTextPressed: warningPressed,
    warningBg: color.yellow.yellow01,
    warningBgHover: color.yellow.yellow02,
    warningBorderDefault: color.yellow.yellow03,
    warningBorderHover: color.yellow.yellow04,
  },
  error: color.red.red06,
  errorHover: color.red.red07,
  errorPressed,
  errorVariant: color.red.red07,
  errorVariantHover: color.red.red06,
  errorVariantPressed: errorPressed,
  errorText: color.red.red08,
  errorTextHover: color.red.red07,
  erorTextPressed: errorPressed,
  errorBg: color.red.red01,
  errorBgHover: color.red.red02,
  errorBorder: color.red.red03,
  errorBorderHover: color.red.red04,
};
const dataviz = {
  dvPrimaryGoldSubtle: primary.primaryGoldSubtle,
  dvPrimaryGoldDefault: primary.primaryGold,
  dvPrimaryGoldLow: primary.primaryGoldDeep,
  dvPrimaryGoldLower: color.gold.gold07,
  primaryGoldLowest: color.gold.gold08,
  primaryGoldDeep: color.gold.gold09,
  dvPrimaryGray: color.ink.ink03,
  dvPrimaryBlackSubtle: color.ink.ink06,
  dvPrimaryBlack: primary.primaryBlack,
};
const table = {
  tableColHeaderBg: surface.surfaceInverse,
  onTableColHeaderBg: text.textInverse,
  tableColActionHoverBg: colorFillInverseSecondary,
  tableColActionPressedBg: colorFillInverseDefault,
  tableColActionIcon: color.ink.ink02,
  tableColActionIconHover: color.sky.sky07,
  tableColActionIconPressed: color.sky.sky01,
  tableColActionIconActive: color.gold.gold04,
  tableColBorderInverse: color.ink.ink04,
  tableColBorder: border.borderDeep,
  tableRowBorder: border.borderDefault,
};
const timeline = {
  timelineNeutral: neutral.neutralDefault,
  timelineLoading: icon.iconDefault,
  timelineInfo: info.infoVariant,
  timelineSuccess: success.successDefault,
  timelineWarning: error.warning.warningVariant,
  timelineError: error.error,
  timelineNeutralInverse: neutral.neutralVariant,
};
const badgeRedDot = error.error;
const badgeOrangeDot = color.orange.orange06;
const badgeYellowDot = color.yellow.yellow05;
const badgeGreenDot = success.successDefault;
const badgeBlueDot = info.infoVariant;
const badgePurpleDot = color.purple.purple06;
const badgeLightGrayDot = color.ink.ink02;
const badgeDarkGraySolidBg = color.ink.ink06;
const badgeDarkGrayDot = badgeDarkGraySolidBg;

export const badge = {
  badgeRedSolidBg: error.errorVariant,
  badgeRedSubtleBg: color.red.red02,
  badgeRedDot,
  badgeRedDotSubtle: color.red.red04,
  badgeRedDotBorder: badgeRedDot,
  badgeOrangeSolidBg: color.orange.orange05,
  badgeOrangeSubtleBg: color.orange.orange02,
  badgeOrangeDot,
  badgeOrangeDotSubtle: color.orange.orange04,
  badgeOrangeDotBorder: badgeOrangeDot,
  badgeYellowSolidBg: error.warning.warningSubtle,
  badgeYellowSubtleBg: color.yellow.yellow02,
  badgeYellowDot,
  badgeYellowDotSubtle: color.yellow.yellow04,
  badgeYellowDotBorder: badgeYellowDot,
  badgeGreenSolidBg: success.successText,
  badgeGreenSubtleBg: color.green.green02,
  badgeGreenDot,
  badgeGreenDotSubtle: color.green.green04,
  badgeGreenDotBorder: badgeGreenDot,
  badgeBlueSolidBg: info.infoDefault,
  onBadgeBlueSolidBg: text.textDefault,
  badgeBlueSubtleBg: color.blue.blue02,
  badgeBlueDot,
  badgeBlueDotSubtle: color.blue.blue03,
  badgeBlueDotBorder: badgeBlueDot,
  badgePurpleSolidBg: color.purple.purple07,
  badgePurpleSubtleBg: color.purple.purple03,
  badgePurpleDot,
  badgePurpleDotSubtle: color.purple.purple04,
  badgePurpleDotBorder: badgePurpleDot,
  badgeLightGraySolidBg: color.ink.ink03,
  badgeLightGraySubtleBg: color.sky.sky05,
  badgeLightGrayDot,
  badgeLightGrayDotSubtle: color.sky.sky06,
  badgeLightGrayDotBorder: badgeLightGrayDot,
  badgeDarkGraySolidBg,
  badgeDarkGraySubtleBg: color.sky.sky07,
  badgeDarkGrayDot,
  badgeDarkGrayDotSubtle: color.sky.sky10,
  badgeDarkGrayDotBorder: badgeDarkGrayDot,
  badgeDisabled: colorFillQuaternary,
  badgeDisabledDotBorder: colorFillSecondary,
  badgeSubtleNeutralSurface: 'rgba(251, 251, 251, 0.18)',
  badgeSubtleNeutralOnSurface: 'rgba(251, 251, 251, 0.69)',
  badgeEmphasisBlueSurface: '#0079B5',
  badgeEmphasisBlueOnSurface: color.sky.sky02,
  badgeEmphasisPrimarySurface: primary.primaryGold,
  badgeEmphasisPrimaryOnSurface: color.ink.ink10,
  badgeEmphasisPurpleSurface: '#7C54BB',
  badgeEmphasisPurpleOnSurface: color.sky.sky02,
  badgeEmphasisOrangeSurface: '#FA7101',
  badgeEmphasisOrangeOnSurface: color.ink.ink10,
  badgeEmphasisGreenSurface: '#337001',
  badgeEmphasisGreenOnSurface: color.sky.sky02,
  onBadgeDisabled: text.textDisabled,
};
const tag = {
  tagRedSolidBg: badge.badgeRedSolidBg,
  tagRedSubtleBg: error.errorBg,
  tagRedSubtleBorder: error.errorBorder,
  tagOrangeSolidBg: badge.badgeOrangeSolidBg,
  tagOrangeSubtleBg: color.orange.orange01,
  tagOrangeSubtleBorder: color.orange.orange03,
  tagYellowSolidBg: badge.badgeYellowSolidBg,
  tagYellowSubtleBg: error.warning.warningBg,
  tagYellowSubtleBorder: error.warning.warningBorderDefault,
  tagGreenSolidBg: badge.badgeGreenSolidBg,
  tagGreenSubtleBg: success.successBg,
  tagGreenSubtleBorder: success.successBorderDefault,
  tagBlueSolidBg: badge.badgeBlueSolidBg,
  tagBlueSubtleBg: info.infoBg,
  tagBlueSubtleBorder: info.infoBorder,
  tagPurpleSolidBg: badge.badgePurpleSolidBg,
  tagPurpleSubtleBg: color.purple.purple01,
  tagPurpleSubtleBorder: color.purple.purple04,
  tagDarkGraySolidBg: badge.badgeDarkGraySolidBg,
  tagDarkGraySubtleBg: surface.surfaceLowest,
  tagDarkGraySubtleBorder: color.sky.sky09,
  tagLightGraySolidBg: badge.badgeLightGraySolidBg,
  tagLightGraySubtleBg: surface.surfaceLower,
  tagLightGraySubtleBorder: color.sky.sky07,
  // tagSubtleDisabledBg: badge.badgeSubtleDisabledBg,
  // tagSubtleDisabledBorder: badge.badgeSubtleDisabledBorder,
  // tagSolidDisabledBg: badge.badgeSolidDisabledBg,
};
const btnPrimaryDefault = primary.primaryBlack;
const btnPrimaryHover = primary.primaryGoldDeepHover;
const btnPrimaryPressed = primary.primaryGoldDeepPressed;
const btnErrorDefault = error.error;
const btnErrorHover = error.errorHover;
const btnErrorActive = error.errorPressed;
const btn = {
  onBtnPrimary: text.textInverse,
  btnPrimaryDefault,
  btnPrimarySplitBorder: color.ink.ink06,
  btnPrimaryDangerSplitBorder: color.red.red05,
  btnPrimaryHover,
  btnPrimaryPressed,
  onBtnDefaultBg: btnPrimaryDefault,
  onBtnDefaultBgHover: btnPrimaryHover,
  onBtnDefaultBgActive: btnPrimaryPressed,
  btnDefaultBg: surface.surfaceHigh,
  btnDefaultBorder: border.borderDeep,
  btnContainerHover: surface.surfaceHoverSubtle,
  btnContainerPressed: surface.surfacePressedSubtle,
  onBtnError: text.textInverse,
  btnErrorBgHover: error.errorBg,
  btnErrorBgPressed: error.errorBgHover,
  btnErrorDefault,
  btnErrorHover,
  btnErrorActive,
  btnErrorBorder: btnErrorDefault,
  btnErrorBorderHover: btnErrorHover,
  btnErrorBorderActive: btnErrorActive,
};
const menu = {
  menuLvl1Bg: surface.surfaceDefault,
  menuLvl2Bg: surface.surfaceLow,
  menuLvl3Bg: surface.surfaceLower,
  menuItemBgHover: surface.surfaceHover,
  menuItemBgPress: surface.surfacePressed,
  menuItemBgActive: primary.primaryGoldBgTransSubtle,
  menuItemBgActivePressed: primary.primaryGoldBgTransHover,
  menuItemText: text.textDefault,
  menuItemActiveText: color.gold.gold09,
  menuItemHorizontalHoverColor: color.gold.gold04,
  sidebarMenuWidth: 250,
};
const inputContainerDefault = surface.surfaceHigh;
const input = {
  inputContainerDefault,
  textboxResizeHandle: border.borderDeeper,
  inputContainerHover: colorFillQuaternary,
  inputContainerActive: inputContainerDefault,
  inputBorderDefault: colorFillDefault,
  inputBorderHover: color.blue.blue07,
  inputBorderActive: color.blue.blue06,
  inputWarningDefault: color.yellow.yellow06,
  inputWarningHover: color.yellow.yellow07,
  inputWarningActive: color.yellow.yellow06,
  inputErrorDefault: color.red.red06,
  inputErrorHover: color.red.red07,
  inputErrorActive: color.red.red06,
  inputSuccessDefault: color.green.green06,
  inputSuccessHover: color.green.green07,
  inputSuccessActive: color.green.green06,
  inputPrefixSuffixBg: colorFillTertiary,
};
const checkbox = {
  onCheckboxSelected: text.textInverse,
  checkboxSelected: primary.primaryBlack,
  checkboxHover: primary.primaryGoldVariantHover,
  checkboxPressed: primary.primaryGoldVariantPressed,
  checkboxDefaultBg: input.inputContainerDefault,
  checkboxDefaultBgHover: surface.surfaceHover,
  checkboxDefaultBgPressed: surface.surfacePressed,
  checkboxBorder: border.depreciate.borderDeeperSolid,
  onCheckboxSecondarySelected: primary.primaryBlack,
  checkboxSecondarySelected: primary.primaryGoldVariant,
  checkboxSecondarySelectedBorder: primary.primaryGoldDeep,
  checkboxSecondaryHover: primary.primaryGoldVariantHover,
  checkboxSecondaryPressed: primary.primaryGoldVariantPressed,
};
const radioBtnDefaultBg = input.inputContainerDefault;
const radio = {
  onRadioDefaultBg: primary.primaryBlack,
  radioDefaultBg: input.inputContainerDefault,
  radioBorder: border.depreciate.borderDeepSolid,
  radioHover: checkbox.checkboxHover,
  radioPressed: checkbox.checkboxPressed,
  radioBorderActive: primary.primaryBlack,
  radioBtnBorder: border.depreciate.borderVariantSolid,
  onRadioBtnBgDefault: text.textDefault,
  radioBtnDefaultBg,
  onRadioBtnPrimarySelectedBg: text.textInverse,
  radioBtnPrimarySelectedBgDefault: primary.primaryBlack,
  radioBtnPrimarySelectedBgHover: primary.primaryGoldDeepHover,
  radioBtnPrimarySelectedBgActive: primary.primaryGoldDeepPressed,
  onRadioBtnSecondaryBg: primary.primaryGoldText,
  radioBtnSecondaryBg: radioBtnDefaultBg,
  radioBtnSecondarySelectedBg: primary.primaryGoldBgTrans,
};
const pagination = {
  onPaginationActiveBg: text.textDefault,
  onPaginationDefaultBg: btn.onBtnDefaultBg,
  paginationActiveBg: controlItemBgActive,
  paginationDefaultBgHover: surface.surfaceHover,
  paginationDefaultBgPressed: surface.surfacePressed,
};
const code = {
  codeBg: colorFillTertiary,
};
const switchTokens = {
  switchOffBg: surface.surfaceDeep,
  switchDisabledBg: 'rgba(25, 25, 25, 0.1500)',
  onSwitchDisabledBg: text.textPlaceholder,
  switchOffBgHover: color.sky.sky10,
  switchOnBg: primary.primaryGoldDeep,
  switchOnBgHover: primary.primaryGoldDeepHover,
  switchOnSuccessBg: success.successText,
  switchOnSuccessBgHover: success.successTextHover,
  switchCircle: surface.surfaceHigh,
  switchCircleDisabled: surface.surfaceDefault,
};
const message = {
  toastMessageBg: surface.surfaceInverse,
  onToastMessageBg: text.textInverse,
  infoOnToastMessageBg: color.blue.blue05,
  warningOnToastMessageBg: color.yellow.yellow04,
  errorOnToastMessageBg: color.red.red05,
  successOnToastBg: color.green.green05,
  messageNeutralBg: colorFillQuaternary,
  messageNeutralBorder: border.borderDefault,
  messageLoadingBg: colorFillQuaternary,
  messageLoadingBorder: border.borderDefault,
};
const stepCompleteBg = primary.primaryBlack;
const steps = {
  onStepErrorBg: text.textInverse,
  stepErrorBg: error.error,
  stepErrorBgHover: error.errorHover,
  onStepCurrentBg: text.textDefault,
  stepCurrentBg: primary.primaryGold,
  stepCurrentDot: primary.primaryGoldVariant,
  onStepCompleteBg: text.textInverse,
  stepCompleteBg,
  stepCompleteDot: stepCompleteBg,
  onStepIncompleteBg: text.textPlaceholder,
  stepIncompleteBg: colorFillContent,
  stepIncompleteDot: border.depreciate.borderVariantSolid,
  stepIncompleteDotHover: border.depreciate.borderVariantSolid,
  stepProgressCircleOutlineFull: border.depreciate.borderDefaultSolid,
  stepProgressCircleOutlinePartial: icon.iconPrimary,
  stepConnectingBorderComplete: primary.primaryBlack,
  stepConnectingBorder: border.depreciate.borderVariantSolid,
  stepHover: primary.primaryGoldDeepHover,
};
const calendarDaySelectedBg = primary.primaryGoldText;
const calendar = {
  onCalendarDaySelectedBg: text.textInverse,
  calendarDaySelectedBg,
  onCalendarDaySelectedHover: primary.primaryGoldTextHover,
  onCalendarDaySelectedPressed: primary.primaryGoldTextPressed,
  calendarRangeBg: primary.primaryGoldVariant,
  calendarRangeBgHover: primary.primaryGoldVariantHover,
  calendarItemBgHover: surface.surfaceHover,
  calendarItemHoverBorder: calendarDaySelectedBg,
  calendarCurrendDayBorder: calendarDaySelectedBg,
  calendarCurrentDayDisabledBorder: border.depreciate.borderVariantSolid,
  calendarSelectedMonthYearBg: primary.primaryGoldVariant,
};
const segmented = {
  segmentedContainer: colorFillSecondary,
};
const progress = {
  progressBg: colorFillSecondary,
  progressBarPrimary: color.gold.gold05,
};
const tooltip = {
  tooltipBtnLink: color.gold.gold04,
};
const sliderHandleBorder = info.infoVariant;
const sliderHandleBorderHover = info.infoVariantHover;
const slider = {
  sliderRail: border.depreciate.borderDefaultSolid,
  sliderHandleBorder,
  sliderRange: sliderHandleBorder,
  sliderHandleBorderDisabled: border.borderDisabled,
  sliderHandleBg: surface.surfaceHigh,
  sliderHandleBgDisabled: surface.surfaceLow,
  sliderHandleBorderHover,
  sliderHandleBorderActive: sliderHandleBorderHover,
};
const avatar = {
  avatarDefaultBg: neutral.neutralSubtle,
  avatarDefaultBgHover: neutral.neutralSubtleHover,
  avatarDefaultBgPressed: neutral.neutralSubtlePressed,
  avatarInverseBg: surface.surfaceInverse,
  avatarBorder: 'rgba(0,0,0,0)',
};

// Ends section generated from Figma token export

const failure = {
  failureIcon: color.ink.ink02,
  failureOnToast: color.ink.ink01,
  failureDot: color.ink.ink02,
};

export type TagColor = 'gray';
export type TagTokens = {
  background: string;
  border: string;
  text: string;
};

export type TShirtSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type ContainerSize = TShirtSize;

const container: Record<ContainerSize, number> = {
  xxs: 50,
  xs: 100,
  sm: 200,
  md: 300,
  lg: 450,
  xl: 600,
  xxl: 800,
};

export type SpaceSize = TShirtSize | 'none';

const space: Record<SpaceSize, number> = {
  none: size.size000,
  xxs: size.size050,
  xs: size.size100,
  sm: size.size200,
  md: size.size300,
  lg: size.size400,
  xl: size.size500,
  xxl: size.size600,
};

export type FontSize = Exclude<Exclude<TShirtSize, 'xxs'>, 'xxl'>;

const font: Record<FontSize, number> = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 19,
};

export type TitleSize = Exclude<TShirtSize, 'xxs'>;
const title: Record<TitleSize, number> = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 29,
  xl: 35,
  xxl: 44,
};

export type IconSize = Exclude<Exclude<TShirtSize, 'xl'>, 'xxl'>;
const iconSize: Record<IconSize, number> = {
  xxs: 22,
  xs: 24,
  sm: 32,
  md: 40,
  lg: 48,
};

const fontFamily = {
  normal: {
    fontFamily: 'DM Sans, sans-serif',
    fontWeight: 400,
  },
  monospace: {
    fontFamily: 'DM Mono, monospace',
    fontWeight: 300,
  },
};

// Utility functions to properly type sub-properties.
function tshirtSize<T>(sizes: Record<TShirtSize, T>): Record<TShirtSize, T> {
  return sizes;
}

export type StatusColorType = PresetStatusColorType | 'failure' | 'info';
function statusType<T>(statuses: Record<StatusColorType, T>): Record<StatusColorType, T> {
  return statuses;
}

const badgeSemanticSolid = statusType({
  default: { backgroundColor: badge.badgeDarkGraySolidBg, color: text.textInverse },
  failure: { backgroundColor: badge.badgeDarkGraySolidBg, color: text.textInverse },
  success: { backgroundColor: badge.badgeGreenSolidBg, color: text.textInverse },
  info: { backgroundColor: badge.badgeBlueSolidBg, color: text.textDefault },
  error: { backgroundColor: badge.badgeRedSolidBg, color: text.textInverse },
  warning: { backgroundColor: badge.badgeOrangeSolidBg, color: text.textDefault },
  processing: { backgroundColor: badge.badgeDarkGraySolidBg, color: text.textInverse },
});

const badgeSemanticSubtle = statusType({
  default: { backgroundColor: badge.badgeDarkGraySubtleBg, color: text.textDefault },
  failure: { backgroundColor: badge.badgeDarkGraySubtleBg, color: text.textDefault },
  success: { backgroundColor: badge.badgeGreenSubtleBg, color: text.textDefault },
  info: { backgroundColor: badge.badgeBlueSubtleBg, color: text.textDefault },
  error: { backgroundColor: badge.badgeRedSubtleBg, color: text.textDefault },
  warning: { backgroundColor: badge.badgeOrangeSubtleBg, color: text.textDefault },
  processing: { backgroundColor: badge.badgeDarkGraySubtleBg, color: text.textDefault },
});

export const tokens = {
  font: fontFamily,
  size: {
    ...size,
    space,
    container,
    font,
    icon: iconSize,
    title,
    menu: { width: menu.sidebarMenuWidth },
    modal: {
      md: 800,
    },
    card: tshirtSize({
      xxs: 4,
      xs: 8,
      sm: 16,
      md: 24,
      lg: 32,
      xl: 40,
      xxl: 48,
    }),
    aside: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
    },
    listSpacing: {
      xs: 4,
      sm: 8,
      md: 16,
    },
    skeleton: tshirtSize({
      xxs: size.size050,
      xs: size.size100,
      sm: size.size200,
      md: size.size300,
      lg: size.size500,
      xl: size.size800,
      xxl: size.size1000,
    }),
  },
  color: {
    ...color,
    primary,
    success,
    error,
    info,
    failure,
    text,
    border,
    surface,
    components: {
      input,
      link,
      avatar,
      logo: { text: color.ink.ink09 },
      tag,
      message,
      badge: {
        semantic: {
          solid: badgeSemanticSolid,
          subtle: badgeSemanticSubtle,
        },
        primitive: badge,
      },
      segmented,
      dataviz,
      table,
      timeline,
      menu,
      radio,
      pagination,
      code,
      steps,
      calendar,
      progress,
      tooltip,
      slider,
      switch: switchTokens,
      icon,
      button: btn,
    },
  },
};

export const tokensDark = {
  ...tokens,
  color: {
    ...tokens.color,
    components: {
      ...tokens.color.components,
      logo: { text: color.sky.sky02 },
      avatar: {
        ...tokens.color.components.avatar,
        avatarDefaultBg: '#5b5b5b',
        avatarBorder: 'rgba(251, 251, 251, 0.13)',
      },
      menu: {
        ...tokens.color.components.menu,
        menulvl1Bg: '#2B2B2B',
        menuLvl2Bg: '#343434',
      },
    },
  },
};

export const antdTokens: Partial<AliasToken> = {
  colorErrorBg: error.errorBg,
  colorText,
  colorTextSecondary,
  colorTextTertiary,
  colorTextQuaternary,
  // colorTextInverse,
  // colorTextInverseSecondary,
  // colorTextInverseTertiary,
  // colorTextInverseQuaternary,
  colorBgContainer,
  colorBgElevated,
  colorBgLayout,
  colorBgMask,
  colorBgSpotlight,
  // colorFillDefault,
  colorFillSecondary,
  colorFillTertiary,
  colorFillQuaternary,
  // colorFillInverseDefault,
  // colorFillInverseSecondary,
  // colorFillInverseTertiary,
  // colorFillInverseQuaternary,
  colorFillContent,
  colorFillAlter,
  colorBorder,
  colorBorderSecondary,
  colorSplit,
  controlItemBgHover,
  controlItemBgActive,
  // controlItemBgActiveSubtle,
  controlItemBgActiveHover,
  colorPrimary: tokens.color.primary.primaryBlack,
  colorPrimaryActive: tokens.color.primary.primaryGoldVariantPressed,
  colorPrimaryHover: tokens.color.primary.primaryGoldDeepHover,
  borderRadius: 2,
  colorLink: tokens.color.components.link.linkDefault,
  colorLinkActive: tokens.color.components.link.linkActive,
  colorLinkHover: tokens.color.components.link.linkHover,
  colorTextBase: tokens.color.text.textDefault,
  colorError: tokens.color.error.errorText,
  linkDecoration: 'underline',
  fontSize: tokens.size.font.lg,
  fontSizeHeading1: tokens.size.title.xxl,
  fontSizeHeading2: tokens.size.title.xl,
  fontSizeHeading3: tokens.size.title.lg,
  fontSizeHeading4: tokens.size.title.md,
  fontSizeHeading5: tokens.size.title.sm,
  lineHeightHeading1: 1.1,
  lineHeightHeading2: 1.1,
  lineHeightHeading3: 1.1,
  lineHeightHeading4: 1.1,
  lineHeightHeading5: 1.1,
  fontFamily: tokens.font.normal.fontFamily,
  fontFamilyCode: tokens.font.monospace.fontFamily,
};

export const antdComponentConfig: Partial<ThemeConfig['components']> = {
  Alert: {
    withDescriptionIconSize: tokens.size.icon.xs,
  },
  Notification: {
    colorBgElevated: tokens.color.ink.ink07,
    colorBgBlur: tokens.color.ink.ink07,
    colorTextHeading: tokens.color.text.textInverse,
    colorIcon: tokens.color.text.textInverse,
  },
  Steps: {
    colorPrimary: tokens.color.gold.gold04,
  },
  Button: {
    linkHoverDecoration: 'underline',
    defaultBg: tokens.color.components.button.btnDefaultBg,
  },
  Layout: {
    siderBg: antdTokens.colorBgLayout,
    headerBg: antdTokens.colorBgLayout,
    footerBg: tokens.color.surface.surfaceLow,
  },
  Menu: {
    itemBg: menu.menuLvl1Bg,
    subMenuItemBg: menu.menuLvl2Bg,
    fontSize: tokens.size.font.md,
  },
  Card: {
    colorBgContainer: tokens.color.sky.sky01,
  },
  Table: {
    headerBg: tokens.color.ink.ink07,
    headerSortHoverBg: tokens.color.ink.ink05,
    headerSortActiveBg: tokens.color.ink.ink06,
    headerColor: tokens.color.sky.sky04,
    colorIcon: tokens.color.ink.ink02,
    headerBorderRadius: 0,
    borderColor: tokens.color.sky.sky06,
    fontSize: tokens.size.font.md,
    headerSplitColor: 'transparent',
    colorBgContainer: tokens.color.sky.sky01,
  },
  Tag: {
    defaultBg: tokens.color.components.tag.tagLightGraySolidBg,
    defaultColor: tokens.color.components.tag.tagBlueSubtleBorder,
  },
  Form: {
    verticalLabelPadding: 0,
    labelFontSize: tokens.size.font.md,
    labelColor: tokens.color.primary.primaryBlackPressed,
    itemMarginBottom: tokens.size.space.lg,
  },
  Message: {
    contentBg: tokens.color.components.message.toastMessageBg,
    colorText: tokens.color.components.message.onToastMessageBg,
    contentPadding: tokens.size.space.xs,
  },
  Badge: {
    colorError: tokens.color.components.badge.semantic.solid.error.backgroundColor,
    colorSuccess: tokens.color.components.badge.semantic.solid.success.backgroundColor,
    colorWarning: tokens.color.components.badge.semantic.solid.warning.backgroundColor,
    colorInfo: tokens.color.components.badge.semantic.solid.info.backgroundColor,
  },
  Segmented: {
    trackBg: tokens.color.components.segmented.segmentedContainer,
  },
};

export const antdComponentDarkConfig: Partial<ThemeConfig['components']> = {
  ...antdComponentConfig,
  Menu: {
    ...antdComponentConfig.Menu,
    itemBg: '#2B2B2B',
    subMenuItemBg: '#343434',
    itemColor: '#BBBBBB',
    itemHoverColor: '#BBBBBB',
    itemSelectedColor: color.ink.ink10,
    itemHoverBg: 'rgba(251, 251, 251, 0.05)',
    popupBg: '#343434',
    dangerItemColor: '#FF957A',
    dangerItemHoverColor: '#FF957A',
  },
};

export const antdTokensDark: Partial<AliasToken> = {
  ...antdTokens,
  colorBgLayout: colorBgLayoutDark,
  colorSplit: 'rgba(251, 251, 251, 0.08)',
  controlItemBgActiveHover: color.gold.gold04,
  controlItemBgHover: 'rgba(251, 251, 251, 0.05)',
};
