/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LoadingSpinnerFullPage } from '@/components/LoadingSpinnerFullPage';
import { useUserProfileQuery } from '@/hooks/query/useUserProfileQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

type AuthenticatedRouteProps = {
  children: React.ReactNode;
};

export function AuthenticatedGuard({ children }: AuthenticatedRouteProps) {
  const setProfile = useMgmtPlaneStateStore((state) => state.setProfile);
  const { refetch, isFetching, isError, data } = useUserProfileQuery();
  const location = useLocation();

  useEffect(() => {
    refetch();
  }, [refetch, location.pathname, location.search, location.hash]);

  useEffect(() => {
    if (isFetching) return;
    setProfile(data?.data);
  }, [data, isFetching, setProfile]);

  if ((!isFetching && !data) || isError) {
    const returnTo = `${location.pathname}${location.search}${location.hash}`;
    sessionStorage.setItem('returnTo', returnTo);
    return <Navigate to="/login" replace />;
  }
  if (isFetching && !data) return <LoadingSpinnerFullPage />;

  return children;
}
