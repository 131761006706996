/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError, GetNodeByIdResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UseNodeByIdQueryProps = {
  nodeId: string;
};

/**
 * Custom hook that fetches and subscribes to node data updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * Features:
 * - Fetches node data by ID
 * - Auto-invalidates query on relevant WebSocket events
 * - Handles tenant context
 * - Type-safe API response handling
 *
 * @param props.nodeId - Unique identifier of the node to fetch
 * @returns React Query result containing node data and query state
 *
 * @example
 * ```tsx
 * const { data: node, isLoading } = useNodeByIdQuery({ nodeId: '123' });
 * ```
 */
export function useNodeByIdQuery({ nodeId }: UseNodeByIdQueryProps) {
  const queryClient = useQueryClient();
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  useSubscribeToEvents(
    [
      'node-config-revision:created',
      'node:operational-state-changed',
      'node:tag-added',
      'node:tag-deleted',
      'node:connectivity-updated',
      'node:updated',
      'node-config:assigned',
      'node-config:applied',
      'node:credential-rotated',
      'node:new-lifecycle-event',
    ],
    (payload) => {
      if (!payload.entityIds.includes(nodeId)) return;
      queryClient.invalidateQueries({ queryKey: ['node', selectedTenantId, nodeId] });
    },
  );

  useSubscribeToEvents(['workspace-membership:updated'], () => {
    queryClient.invalidateQueries({ queryKey: ['node', selectedTenantId] });
  });

  return useQuery<GetNodeByIdResponse, ApiError>({
    queryKey: ['node', selectedTenantId, nodeId],
    queryFn: () => {
      return mgmtPlaneAPIClient.nodes.getNodeById({ id: nodeId, tenantId: selectedTenantId! });
    },
    enabled: !!selectedTenantId && !!nodeId,
  });
}
