/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedJobsQuery } from '@/hooks/query/usePaginatedJobsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';

import { JobBanner } from './JobBanner';

export function JobBanners() {
  const { t } = useMimicTranslation('jobs');

  const jobsQuery = usePaginatedJobsQuery({
    params: {
      filters: {
        notificationDismissed: false,
        status: ['error', 'warning', 'timeout', 'running'].join(','),
      },
      page: 1,
      pageSize: 5,
      sort: ['-jobNumber'],
    },
  });

  if (jobsQuery.isLoading) return null;

  return (
    <div className="job-banners" data-testid="job-banners">
      <QueryWrapper queryResult={jobsQuery} errorToast={t('banner.jobStatusError')}>
        {(jobsData) => (
          <>
            {jobsData.data.map((job) => (
              <JobBanner key={job.id} job={job} />
            ))}
          </>
        )}
      </QueryWrapper>
    </div>
  );
}
