/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { SegmentedValue } from 'antd/es/segmented';
import { Outlet, useSearchParams } from 'react-router-dom';

import { User, UserFilters } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedMembersQuery } from '@/hooks/query/usePaginatedMembersQuery';
import { MembersHeader, UserState } from '@/pages/Members/MembersHeader';
import { MembersList } from '@/pages/Members/MembersList';
import { UpdateParams } from '@/utils/params';

export function MembersPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeMembersQuery = usePaginatedMembersQuery({
    type: 'queryParams',
    params: {
      filters: { removed: false },
    },
    urlPrefix: 'active',
  });
  const removedMembersQuery = usePaginatedMembersQuery({
    type: 'queryParams',
    params: { filters: { removed: true } },
    urlPrefix: 'removed',
  });

  const showRemoved = searchParams.get('removed') === 'true';
  const activeFilterValue = showRemoved ? UserState.REMOVED : UserState.ACTIVE;
  const membersQuery = showRemoved ? removedMembersQuery : activeMembersQuery;
  const activeTotal = activeMembersQuery.data?.meta.page.totalItems;
  const removedTotal = removedMembersQuery.data?.meta.page.totalItems;

  const updateQueryParams: UpdateParams<User, UserFilters> = (params) => {
    const removed = showRemoved;
    const updateCallback = removed ? removedMembersQuery.updateQueryParams : activeMembersQuery.updateQueryParams;

    updateCallback({
      ...params,
      filters: {
        ...params.filters,
        removed,
      },
    });
  };

  const onChangeActiveFilter = (value: SegmentedValue) => {
    const removed = value === UserState.REMOVED;
    if (value === UserState.ACTIVE) {
      updateQueryParams({ filters: { removed: false } });
    } else if (value === UserState.REMOVED) {
      updateQueryParams({ filters: { removed: true } });
    }

    setSearchParams((prev) => {
      prev.set('removed', removed ? 'true' : 'false');
      return prev;
    });
  };

  return (
    <ContentContainer>
      <MembersHeader
        activeFilterValue={activeFilterValue}
        onChangeActiveFilter={onChangeActiveFilter}
        activeTotal={activeTotal!}
        removedTotal={removedTotal!}
        isLoading={membersQuery.isLoading}
      />
      <QueryWrapper queryResult={membersQuery}>
        {(membersData) => (
          <MembersList membersData={membersData} filters={membersQuery.filters} onParamsChange={updateQueryParams} />
        )}
      </QueryWrapper>
      <Outlet />
    </ContentContainer>
  );
}
