/*
 * Copyright Mimic Networks, Inc. 2025.
 */

// This function will return whatever the MIMIC_MGMT_PLANE_ENVIRONMENT env var is, or 'local' if it is not set.
export function mimicEnv() {
  if (window.mimicConfig.MIMIC_MGMT_PLANE_ENVIRONMENT) {
    return String(window.mimicConfig.MIMIC_MGMT_PLANE_ENVIRONMENT);
  }
  return 'local';
}
