/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Workspace } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedWorkspacesQuery } from '@/hooks/query/usePaginatedWorkspacesQuery';
import { DeleteWorkspaceModal } from '@/pages/Workspaces/DeleteWorkspaceModal';
import { WorkspacesHeader } from '@/pages/Workspaces/WorkspacesHeader';
import { WorkspacesList } from '@/pages/Workspaces/WorkspacesList';
import { Container } from '@/primitives/Container';

export function WorkspacesPage() {
  const navigate = useNavigate();
  const workspacesQuery = usePaginatedWorkspacesQuery({ type: 'queryParams' });
  const [deleteWorkspace, setDeleteWorkspace] = useState<Workspace>();
  const [deleteIsPending, setDeleteIsPending] = useState(false);

  const editWorkspace = (workspace: Workspace) => {
    navigate(workspace.id);
  };

  const closeDeleteDialog = () => {
    setDeleteWorkspace(undefined);
    setDeleteIsPending(false);
  };

  return (
    <ContentContainer>
      <Container>
        <WorkspacesHeader />
        <QueryWrapper queryResult={workspacesQuery}>
          {(workspacesData) => (
            <WorkspacesList
              workspacesData={workspacesData}
              onParamsChange={workspacesQuery.updateQueryParams}
              editWorkspace={editWorkspace}
              setDeleteWorkspace={setDeleteWorkspace}
            />
          )}
        </QueryWrapper>

        <DeleteWorkspaceModal
          deleteWorkspace={deleteWorkspace}
          deleteIsPending={deleteIsPending}
          setDeleteIsPending={setDeleteIsPending}
          onCancelWorkspaceDelete={closeDeleteDialog}
        />
      </Container>
    </ContentContainer>
  );
}
