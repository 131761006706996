/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { AppstoreOutlined } from '@ant-design/icons';

import { GetWorkspaceByIdResponse } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useWorkspaceByIdQuery } from '@/hooks/query/useWorkspaceByIdQuery';
import { WorkspaceMembers } from '@/pages/WorkspaceDetailsPage/WorkspaceMembers';
import { Container } from '@/primitives/Container';
import { Text } from '@/primitives/Text';
import { WorkspaceBadge } from '@/primitives/WorkspaceBadge';

export type WorkspaceDetailsPageProps = {
  workspaceId: string;
};

export function WorkspaceDetailsPage({ workspaceId }: WorkspaceDetailsPageProps) {
  const workspaceQuery = useWorkspaceByIdQuery({ workspaceId });

  const editWorkspace = () => {
    // TODO: Implement edit workspace
  };

  return (
    <QueryWrapper<GetWorkspaceByIdResponse> queryResult={workspaceQuery}>
      {({ data: workspace }) => (
        <ContentContainer>
          <Container>
            <PageHeader
              icon={<AppstoreOutlined style={{ width: '18px', height: '18px' }} />}
              title={
                <Text type="subtle" size="md" strong style={{ lineHeight: '32px' }}>
                  Workspace
                </Text>
              }
              text={
                <WorkspaceBadge badgeColor={workspace.badgeColor} size="large">
                  {workspace.name}
                </WorkspaceBadge>
              }
              level={4}
              fontWeight="300"
            >
              <Container justify="end" full style={{ marginRight: '16px' }}>
                <Container gap="sm" justify="flex-end" horizontal style={{ flexWrap: 'wrap' }}>
                  <GuardedButton
                    requiredRole="admin"
                    type="primary"
                    dataTestId="edit-workspace"
                    onClick={editWorkspace}
                  >
                    Edit
                  </GuardedButton>
                </Container>
              </Container>
            </PageHeader>
          </Container>
          <WorkspaceMembers workspace={workspace} />
        </ContentContainer>
      )}
    </QueryWrapper>
  );
}
