/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Sider } from '@/primitives/Sider';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type MainLayoutSiderProps = {
  children: React.ReactNode;
};

export function MainLayoutSider({ children }: MainLayoutSiderProps) {
  const dark = useMgmtPlaneStateStore((state) => state.darkMode);
  const darkModeClass = dark ? 'mimic-dark-mode' : '';

  return <Sider className={darkModeClass}>{children}</Sider>;
}
