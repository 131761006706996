/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Input as AntDInput, InputProps as AntDInputProps, InputRef, Space } from 'antd';
import { createRef, forwardRef, useEffect, useState } from 'react';

import { Text } from './Text';
import './Input.css';

export type CountConfig = {
  max: number;
};

export type InputProps = {
  addonBefore?: AntDInputProps['addonBefore'];
  prefix?: AntDInputProps['prefix'];
  disabled?: AntDInputProps['disabled'];
  placeholder?: AntDInputProps['placeholder'];
  autoFocus?: AntDInputProps['autoFocus'];
  onChange?: AntDInputProps['onChange'];
  onBlur?: AntDInputProps['onBlur'];
  onFocus?: AntDInputProps['onFocus'];
  onClick?: AntDInputProps['onClick'];
  onPressEnter?: AntDInputProps['onPressEnter'];
  maxLength?: AntDInputProps['maxLength'];
  suffix?: AntDInputProps['suffix'];
  value?: AntDInputProps['value'];
  readOnly?: AntDInputProps['readOnly'];
  allowClear?: AntDInputProps['allowClear'];
  dataTestId?: string;
  count?: CountConfig;
  style?: React.CSSProperties;
  type?: AntDInputProps['type'];
  className?: AntDInputProps['className'];
};

type CountSuffixProps = {
  suffix?: AntDInputProps['suffix'];
  value?: AntDInputProps['value'];
  max: CountConfig['max'];
};

// properly deals with string length of emojis and unicode characters
function strLen(str: string) {
  return [...str].length;
}

function CountSuffix({ suffix, max, value }: CountSuffixProps) {
  const val = value === undefined ? '' : value;
  const text = String(val);
  const len = strLen(text);
  const underMax = len <= max;
  const label = underMax ? `${len}/${max}` : `${max - len}/${max}`;

  return (
    <Space>
      {suffix}
      <Text className="ant-input-suffix">{label}</Text>
    </Space>
  );
}

/* eslint react/jsx-props-no-spreading: "off" */
const ForwardedInput = forwardRef<InputRef, InputProps>((props, ref) => {
  return <AntDInput ref={ref} {...props} />;
});

export function Input({
  addonBefore,
  prefix,
  disabled,
  allowClear,
  count,
  placeholder,
  autoFocus,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onPressEnter,
  maxLength,
  value,
  readOnly,
  suffix,
  dataTestId,
  style,
  type,
  className,
}: InputProps) {
  const [val, setVal] = useState(value);
  const onChangeHandler: AntDInputProps['onChange'] = (el) => {
    setVal(el.target.value);
    if (onChange) {
      onChange(el);
    }
  };
  const fullSuffix = count ? <CountSuffix value={val} suffix={suffix} max={count.max} /> : suffix;
  const ref = createRef<InputRef>();

  useEffect(() => {
    if (autoFocus) {
      // This must happen async or focus will not be re-applied on second render
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    }
  }, [autoFocus, ref]);

  return (
    <ForwardedInput
      ref={ref}
      addonBefore={addonBefore}
      disabled={disabled}
      prefix={prefix}
      allowClear={allowClear}
      placeholder={placeholder}
      onChange={onChangeHandler}
      onBlur={onBlur}
      onClick={onClick}
      onPressEnter={onPressEnter}
      onFocus={onFocus}
      maxLength={maxLength}
      value={value}
      suffix={fullSuffix}
      data-testid={dataTestId}
      readOnly={readOnly}
      style={style}
      type={type}
      className={className}
    />
  );
}
