/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Form, FormInstance, FormProps } from 'antd';
import { ReactNode, useState } from 'react';

import { Workspace } from '@/client';
import { ValidationError } from '@/components/Forms/ValidationError';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { ColorPicker } from '@/primitives/ColorPicker';
import { Container } from '@/primitives/Container';
import { Input } from '@/primitives/Input';

export type WorkspaceFormProps = {
  workspace?: Workspace;
  loading: boolean;
  form: FormInstance<WorkspaceFormFields>;
  onFinish?: FormProps['onFinish'];
  okButton?: ReactNode;
  horizontal?: boolean;
};

export type WorkspaceFormFields = {
  name: string;
  badgeColor: string;
};

export function WorkspaceForm({ workspace, loading, form, onFinish, okButton, horizontal }: WorkspaceFormProps) {
  const { t } = useMimicTranslation('workspaces');
  const [badgeColor, setBadgeColor] = useState<string>(workspace?.badgeColor || '#cccccc');
  const maxLength = 30;

  const buttonFormItem = !okButton ? undefined : (
    <Form.Item style={{ marginInlineEnd: 0, margin: 0 }}>{okButton}</Form.Item>
  );

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ name: workspace?.name, badgeColor }}
      layout="vertical"
      style={{ width: '100%' }}
      className="workspace-form"
    >
      <Container horizontal={horizontal} gap="xs" style={{ width: '100%', backgroundColor: 'transparent' }}>
        <Form.Item<WorkspaceFormFields>
          status="error"
          name="name"
          style={{ width: '100%', marginBottom: 0, backgroundColor: 'transparent' }}
          rules={[
            { required: true, message: <ValidationError>{t('workspaceForm.nameRequired')}</ValidationError> },
            {
              max: maxLength,
              message: <ValidationError>{t('workspaceForm.nameMaxLength', { maxLength })}</ValidationError>,
            },
          ]}
        >
          <Input
            disabled={loading}
            addonBefore={
              <ColorPicker
                dataTestId="workspace-badge-color-picker"
                colorHex={badgeColor}
                onChangeColor={(_, hex) => {
                  setBadgeColor(hex);
                  form.setFieldValue('badgeColor', hex);
                }}
              />
            }
            autoFocus
            placeholder={t('addWorkspace.namePlaceholder')}
            onChange={(event) => {
              form.setFieldValue('name', event.target.value);
            }}
            allowClear
            dataTestId="workspace-name-input"
            count={{
              max: maxLength,
            }}
          />
        </Form.Item>
        {buttonFormItem}
      </Container>
    </Form>
  );
}
