/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { StateCreator } from 'zustand';

import { antdComponentConfig, antdComponentDarkConfig, antdTokens, antdTokensDark, tokens, tokensDark } from '@/theme';
import { mimicEnv } from '@/utils/environments';

const darkTheme = { antdTokens: antdTokensDark, antdComponents: antdComponentDarkConfig, tokens: tokensDark };
const lightTheme = { antdTokens, antdComponents: antdComponentConfig, tokens };

export interface ThemeSlice {
  darkMode: boolean;
  theme: typeof lightTheme;
  setDarkMode: (darkMode: boolean) => void;
}

export const createThemeSlice: StateCreator<ThemeSlice, [], [], ThemeSlice> = (set) => ({
  darkMode: mimicEnv() !== 'prod',
  theme: mimicEnv() !== 'prod' ? darkTheme : lightTheme,
  setDarkMode: (isDarkMode: boolean) =>
    set(() => ({
      darkMode: isDarkMode,
      theme: isDarkMode ? darkTheme : lightTheme,
    })),
});
