/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery } from '@tanstack/react-query';

import { ApiError, GetTenantByIdResponse, User } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type UseTenantByIdQueryProps = {
  tenantId: string;
  currentUser?: User;
};

/**
 * Custom hook that fetches tenant data.
 * Utilizes React Query for data fetching.
 *
 * Features:
 * - Fetches tenant data by ID
 * - Handles tenant context
 * - Type-safe API response handling
 *
 * @param options Configuration object
 *   - tenantId: Unique identifier of the tenant to fetch
 *   - currentUser: Current user object
 * @returns React Query result containing tenant data and query state
 *
 * @example
 * ```tsx
 * const { data: tenant, isLoading } = useTenantByIdQuery({ tenantId: '123' });
 * ```
 */
export function useTenantByIdQuery({ tenantId, currentUser }: UseTenantByIdQueryProps) {
  return useQuery<GetTenantByIdResponse, ApiError>({
    queryKey: ['tenant', tenantId],
    queryFn: () => {
      return mgmtPlaneAPIClient.tenants.getTenantById({ id: tenantId });
    },
    enabled: currentUser?.role === 'superadmin' && !!tenantId,
  });
}
