/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { EditOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { SetStateAction } from 'react';

import { Workspace } from '@/client';
import MimicHighlighter from '@/components/MimicHighlighter';
import { useAuthorization } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { WorkspaceActionDropdown } from '@/pages/Workspaces/WorkspaceActionDropdown';
import { Button } from '@/primitives/Button';
import { Space } from '@/primitives/Space';
import { WorkspaceBadge } from '@/primitives/WorkspaceBadge';

export function useWorkspaceListColumns(
  searchText: string,
  editWorkspace: (workspace: Workspace) => void,
  deleteWorkspace: React.Dispatch<SetStateAction<Workspace | undefined>>,
): ColumnsType<Workspace> {
  const { t } = useMimicTranslation('workspaces');
  const canEditWorkspaces = useAuthorization('editor');
  const columns: ColumnsType<Workspace> = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      filterMode: 'menu',
      render: (_, workspace) => {
        return (
          <WorkspaceBadge badgeColor={workspace.badgeColor}>
            <MimicHighlighter searchText={searchText} text={workspace.name} />
          </WorkspaceBadge>
        );
      },
    },
  ];

  if (!canEditWorkspaces) return columns;

  return [
    ...columns,
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      width: 64,
      render: (_, record) => {
        return (
          <Space compact>
            <Button dataTestId="edit-workspace-name" type="default" onClick={() => editWorkspace(record)}>
              <EditOutlined /> {t('actions.editWorkspaceButton')}
            </Button>
            <WorkspaceActionDropdown workspace={record} onDeleteWorkspace={() => deleteWorkspace(record)} />
          </Space>
        );
      },
    },
  ];
}
