/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import { TYPE_UUID } from '../../types';

// base schema
export const WorkspaceSchema = z
  .object({
    id: TYPE_UUID,
    tenantID: TYPE_UUID,
    name: z.string().openapi({ example: 'Sales App' }),
    badgeColor: z.string().openapi({ example: '#cecdcc' }),
  })
  .openapi('Workspace');

// mutation schemas
export const CreateWorkspaceSchema = z
  .object({
    name: z.string().openapi({ example: 'Sales App' }),
    badgeColor: z.string().openapi({ example: '#cecdcc' }),
  })
  .required({
    name: true,
  })
  .openapi('CreateWorkspace');

export const PatchWorkspaceSchema = z
  .object({
    name: z.string().openapi({ example: 'Sales App' }).optional(),
    badgeColor: z.string().openapi({ example: '#cecdcc' }).optional(),
  })
  .openapi('PatchWorkspace');

// response schemas
export const WorkspaceResponseSchema = buildResponseSchema(
  'WorkspaceResponse',
  WorkspaceSchema,
);

export const WorkspacesFilterParamsSchema = z
  .object({
    name: z.string().optional().openapi({ example: 'Sales App' }),
  })
  .openapi('WorkspacesFilter', { example: { name: 'Sales App' } });

export const WorkspacesSortableFields = sortable('WorkspacesSortableFields', [
  'name',
]);

export const WorkspacesQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: WorkspacesFilterParamsSchema.optional(),
    sort: WorkspacesSortableFields.optional(),
  }),
);

export const PaginatedWorkspacesResponseSchema = buildPaginatedResponseSchema(
  'PaginatedWorkspacesResponse',
  WorkspaceSchema,
  WorkspacesFilterParamsSchema,
  WorkspacesSortableFields,
);

// members
export const WorkspaceMember = z
  .object({
    id: TYPE_UUID,
    displayName: z.string().openapi({ example: 'John Doe' }),
    email: z.string().email().openapi({ example: 'john@mimic.com' }),
    role: z
      .enum(['none', 'viewer', 'editor', 'admin'])
      .openapi({ example: 'viewer' }),
  })
  .openapi('WorkspaceMember');

export const WorkspaceMembersSortableFields = sortable(
  'WorkspaceMembersSortableFields',
  ['displayName'],
);

export const WorkspaceMemberFilterParamsSchema = z
  .object({
    role: z.enum(['none', 'viewer', 'editor', 'admin', 'inherit']).optional(),
  })
  .openapi('WorkspaceMemberFilter', { example: { role: 'viewer' } });

export const WorkspaceMembersQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: WorkspaceMemberFilterParamsSchema.optional(),
    sort: WorkspaceMembersSortableFields.optional(),
  }),
);

export const WorkspaceMembersResponseSchema = buildPaginatedResponseSchema(
  'WorkspaceMembersResponse',
  WorkspaceMember,
  WorkspaceMemberFilterParamsSchema,
  WorkspaceMembersSortableFields,
);

export const UpdateWorkspaceMembershipSchema = z
  .object({
    role: z
      .enum(['none', 'viewer', 'editor', 'admin', 'inherit'])
      .openapi({ example: 'viewer' }),
  })
  .openapi('UpdateWorkspaceMembership');

export const UpdateWorkspaceMembershipResponseSchema = buildResponseSchema(
  'UpdateWorkspaceMembership',
  UpdateWorkspaceMembershipSchema,
);
