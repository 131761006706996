/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, ApiToken } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type RevokeApiTokenMutationConfig = {
  onSuccess?: (data: void, variables: ApiToken) => void;
  onError?: (error: unknown, variables: ApiToken) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: ApiToken) => Promise<unknown> | void;
};

/**
 * Hook for revoking an API token by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful API token revocation
 * @param config.onError - Called if API token revocation fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @example
 * ```tsx
 * const { mutate } = useRevokeAPITokenMutation({
 *   onSuccess: (data) => console.log('API token Revoked:', data),
 *   onError: (error) => console.error('API token Revocation failed:', error)
 * });
 *
 * // Revoke API token
 * mutate({ apiTokenId: '9fb763f7-33de-4969-a7a0-b808f4637318' });
 * ```
 */

export function useRevokeAPITokenMutation({ onSuccess, onError, onSettled, onMutate }: RevokeApiTokenMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error, isPending } = useMutation<void, ApiError, ApiToken>({
    mutationFn: async (apiToken: ApiToken) => {
      await mgmtPlaneAPIClient.apiTokens.revokeApiToken({ tenantId: tenantId!, tokenId: apiToken.id });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error, isPending };
}
