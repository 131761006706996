/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Tenant } from '@/client';
import { useTheme } from '@/hooks/useTheme';
import { Avatar } from '@/primitives/Avatar';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export type TenantOptionProps = {
  selectedTenant: Tenant;
  tenant: Tenant;
  searchTerm?: string;
};

export function TenantOption({ selectedTenant, tenant, searchTerm }: TenantOptionProps) {
  const theme = useTheme();
  if (!searchTerm) return null;

  const color =
    tenant.id === selectedTenant?.id
      ? { color: theme.antdComponents?.Menu?.itemSelectedColor }
      : { color: theme.antdComponents?.Menu?.itemColor };

  return (
    <div style={{ display: 'flex', gap: 10, padding: '10px 0' }} data-testid={`tenant-option-${tenant.id}`}>
      <div style={{ flexGrow: 1, display: 'flex', gap: 10, overflow: 'hidden', maxWidth: '165px' }}>
        <Avatar
          size="xxs"
          text={tenant.name.charAt(0).toUpperCase()}
          color={tokens.color.gold.gold03}
          backgroundColor={tokens.color.text.textSubtle}
          dataTestId="tenant-avatar"
          shape="square"
        />
        <Text data-testid="tenant-name-hidden" style={color} ellipsis>
          {maskAllContinuousSubstrings(tenant.name, searchTerm)}
        </Text>
      </div>
    </div>
  );
}

/**
 * Masks characters in a name that don't match the search term
 * @param name - The original name to mask
 * @param searchTerm - Characters to keep unmasked
 * @returns - The masked name with non-matching characters replaced by '•'
 */
function maskAllContinuousSubstrings(name: string, searchTerm: string): string {
  if (!searchTerm) return '•'.repeat(name.length); // Handle empty searchTerm case

  const lowerName = name.toLowerCase();
  const lowerSearchTerm = searchTerm.toLowerCase();

  const masked: string[] = '•'.repeat(name.length).split(''); // Start with all dashes
  let start = 0;
  let index: number;

  // eslint-disable-next-line no-cond-assign
  while ((index = lowerName.indexOf(lowerSearchTerm, start)) !== -1) {
    // Reveal the original characters from the input name (preserving case)
    for (let i = 0; i < searchTerm.length; i += 1) {
      masked[index + i] = name[index + i];
    }
    start = index + 1; // Move forward to find the next occurrence
  }

  return masked.join('');
}
