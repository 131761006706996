/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ColumnsType } from 'antd/es/table';

import { ApiToken, ApiTokenFilters, PaginatedApiTokensResponse } from '@/client';
import MimicHighlighter from '@/components/MimicHighlighter';
import { PaginatedTable } from '@/components/PaginatedTable';
import { RelativeTime } from '@/components/RelativeTime';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { UpdateParams } from '@/utils/params';

export type APITokensTableProps = {
  apiTokensData: PaginatedApiTokensResponse;
  apiTokenFilters: ApiTokenFilters;
  updateQueryParams: UpdateParams<ApiToken, Partial<ApiTokenFilters>>;
  revokeAPIToken: (apiToken: ApiToken) => void;
};

export function APITokensTable({
  apiTokensData,
  updateQueryParams,
  apiTokenFilters,
  revokeAPIToken,
}: APITokensTableProps) {
  const { t } = useMimicTranslation('apiTokens');
  const columns = getApiTokenColumns(t, apiTokenFilters, revokeAPIToken);
  return (
    <PaginatedTable
      data={apiTokensData}
      columns={columns}
      rowKey={(config) => config.id}
      data-testid="api-tokens-table"
      onParamsChange={updateQueryParams}
    />
  );
}

function getApiTokenColumns(
  t: MimicTranslationFunction<'apiTokens'>,
  apiTokenFilters: ApiTokenFilters,
  revokeAPIToken: (apiToken: ApiToken) => void,
): ColumnsType<ApiToken> {
  return [
    {
      title: <span data-testid="apiTokens.name">{t('table.apiTokenName')}</span>,
      dataIndex: 'name',
      sorter: true,
      filterMode: 'menu',
      render: (name) => {
        return (
          <Flex gap="xxs">
            <MimicHighlighter searchText={apiTokenFilters?.name} text={name} />
          </Flex>
        );
      },
    },
    {
      title: <span data-testid="apiTokens.createdBy">{t('table.createdBy')}</span>,
      dataIndex: 'createBy',
      render: (_, token: ApiToken) => {
        return (
          <Text size="md" type="subtle" data-testid="api-token-date-created">
            {token.createdBy?.displayName}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="apiTokens.dateCreated">{t('table.dateCreated')}</span>,
      dataIndex: 'dateCreated',
      sorter: true,
      render: (dateCreated: string) => (
        <Text size="md" type="subtle" data-testid="api-token-date-created">
          <RelativeTime date={dateCreated} />
        </Text>
      ),
      width: '240px',
    },
    {
      title: <span data-testid="apiTokens.revokeToken">{t('table.revoke')}</span>,
      dataIndex: 'id',
      render: (_, apiToken: ApiToken) => (
        <Button
          dataTestId="revoke-api-token"
          type="primary"
          data-testid="revoke-button"
          onClick={() => revokeAPIToken(apiToken)}
        >
          {t('table.revoke')}
        </Button>
      ),
      width: '120px',
    },
  ];
}
