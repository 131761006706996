/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import { UUID_NEXACORP } from '../../constants';
import {
  PaginationParams,
  baseSchema,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import { TYPE_UUID } from '../../types';

// base schema
export const BaseTenantSchema = z.object({
  id: TYPE_UUID.openapi({
    'x-go-name': 'ID',
    example: UUID_NEXACORP,
  }),
  name: z.string().openapi({ example: 'OrionTechs LLC' }),
  identityProviderID: z
    .string()
    .regex(/^(saml|oidc)\.[a-z0-9-]+$/)
    .openapi({ example: 'saml.oriontechs' }),
  domain: z
    .string()
    .regex(/^[a-z0-9-]+(\.[a-z0-9-]+)*$/)
    .openapi({ example: 'oriontechs.co' }),
  development: z.boolean().openapi({ example: false }),
});

export const TenantSchema =
  BaseTenantSchema.merge(baseSchema).openapi('Tenant');

// mutation schemas
export const CreateTenantSchema = z
  .object({
    name: z.string().min(3).max(100).openapi({ example: 'OrionTechs LLC' }),
    identityProviderID: z
      .string()
      .min(3)
      .max(100)
      .regex(/^(saml|oidc)\.[a-z0-9-]+$/)
      .openapi({ example: 'saml.oriontechs' }),
    domain: z
      .string()
      .min(3)
      .max(100)
      .regex(/^[a-z0-9-]+(\.[a-z0-9-]+)*$/)
      .openapi({ example: 'oriontechs.co' }),
    development: z.boolean().openapi({ example: false }),
  })
  .required({
    name: true,
    identityProviderID: true,
    domain: true,
  })
  .openapi('CreateTenant');

export const PatchTenantSchema = z
  .object({
    name: z.string().optional().openapi({ example: 'OrionTechs LLC' }),
    identityProviderID: z
      .string()
      .regex(/^(saml|oidc)\.[a-z0-9-]+$/)
      .optional()
      .openapi({ example: 'saml.oriontechs' }),
    domain: z
      .string()
      .regex(/^[a-z0-9-]+(\.[a-z0-9-]+)*$/)
      .optional()
      .openapi({ example: 'oriontechs.co' }),
  })
  .openapi('PatchTenant');

// response schemas
export const TenantResponseSchema = buildResponseSchema(
  'TenantResponse',
  TenantSchema,
);

export const TenantFiltersParamsSchema = z
  .object({
    name: z.string().optional().openapi({ example: 'OrionTechs LLC' }),
  })
  .openapi('TenantFilters');
export const TenantSortableFields = sortable('TenantSortableFields', []);

export const PaginatedTenantsResponseSchema = buildPaginatedResponseSchema(
  'PaginatedTenantsResponse',
  TenantSchema,
  TenantFiltersParamsSchema,
  TenantSortableFields,
);

export const TenantsQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: TenantFiltersParamsSchema.optional(),
    sort: TenantSortableFields.optional(),
  }),
);
