/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, CreateApiTokenResponse, CreateToken } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type NewAPITokenMutationConfig = {
  onSuccess?: (data: CreateApiTokenResponse, variables: CreateToken) => void;
  onError?: (error: unknown, variables: CreateToken) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

/**
 * Hook for creating a new API token through the management plane API.
 *
 * @param config.onSuccess - Called after successful API tokens creation
 * @param config.onError - Called if creation fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for creating API tokens
 *
 * @example
 * ```tsx
 * const { mutate } = useNewAPITokenMutation({
 *   onSuccess: (data) => console.log('New API token:', data.token),
 *   onError: (error) => console.error('API token creation failed:', error)
 * });
 *
 * // Create API token
 * mutate({ name: 'My Token' });
 * ```
 */

export function useNewAPITokenMutation(config: NewAPITokenMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error, isPending } = useMutation<CreateApiTokenResponse, ApiError, CreateToken>({
    mutationFn: (token: CreateToken) => {
      return mgmtPlaneAPIClient.apiTokens.createApiToken({
        tenantId: tenantId!,
        requestBody: {
          name: token.name,
        },
      });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
    onSuccess: config?.onSuccess,
  });

  return { mutate, isError, error, isPending };
}
