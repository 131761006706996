/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { JobFilterParamsSchema } from 'openapi-spec/api/routes/jobs/schemas';

import { GetJobsData, GetJobsResponse, Job, JobFilters, JobSortableFields } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedJobsQueryParams = {
  params?: Omit<GetJobsData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated jobs with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated jobs response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedJobsQuery({ params, type }: UsePaginatedJobsQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<GetJobsResponse, Job, JobFilters, JobSortableFields, GetJobsData>({
    apiCall: mgmtPlaneAPIClient.jobs.getJobs.bind(mgmtPlaneAPIClient.jobs),
    filterTypes: zodSchemaToTypeMap(JobFilterParamsSchema.shape),
    queryKey: 'jobs',
    realtimeEvents: [
      'node:operational-state-changed',
      'node-config:assigned',
      'node:update-baseline',
      'job:updated',
      'job:completed',
      'job:timedout',
      'workspace-membership:updated',
    ],
    params: {
      tenantId: selectedTenantId!,
      ...params,
    },
    type,
  });
}
