/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useTheme } from '@/hooks/useTheme';

import MimicLogoStyles from './MimicLogo.module.css';
import MimicLogoSvg from './MimicLogo.svg?react';

export type MimicLogoProps = {
  width?: string;
  height?: string;
  color?: string;
};

export function MimicLogo({ width, height, color }: MimicLogoProps) {
  const theme = useTheme();
  const defaultColor = theme.tokens.color.components.logo.text;
  return (
    <MimicLogoSvg
      style={{
        width,
        height,
        color: color || defaultColor,
      }}
      className={`${MimicLogoStyles.logo} anticon`}
    />
  );
}
