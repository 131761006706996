/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { DownOutlined, InboxOutlined } from '@ant-design/icons';

import { Workspace } from '@/client';
import { useAuthorization } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Dropdown, MenuProps } from '@/primitives/Dropdown';
import { Space } from '@/primitives/Space';

enum ActionType {
  DELETE = 'delete',
}

export type WorkspaceActionDropdownParams = {
  workspace: Workspace;
  onDeleteWorkspace: (workspace: Workspace) => void;
};

export function WorkspaceActionDropdown({ workspace, onDeleteWorkspace }: WorkspaceActionDropdownParams) {
  const { t } = useMimicTranslation('workspaces');
  const handleActionClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case ActionType.DELETE:
        onDeleteWorkspace(workspace);
        break;
      default:
        break;
    }
  };

  const canManageWorkspaces = useAuthorization('editor');
  if (!canManageWorkspaces) return null;

  const items: MenuProps['items'] = [
    {
      label: (
        <Space>
          <InboxOutlined />
          {t('actions.deleteWorkspace')}
        </Space>
      ),
      danger: true,
      key: ActionType.DELETE,
    },
  ];

  const menu = {
    items,
    onClick: handleActionClick,
  };

  return (
    <Dropdown menu={menu}>
      <Button type="default" dataTestId="workspace-actions">
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}
