/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Node } from '@/client';
import { GuardedButton } from '@/components/GuardedButton';
import { NodeViewPageCard } from '@/components/Node/NodeViewPageCard';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { ErrorIcon } from '@/primitives/Icons';
import NoData from '@/primitives/Icons/NoData.svg?react';
import { Text } from '@/primitives/Text';

export type AssignConfigProps = {
  node: Node;
  assignNewNodeConfig: () => void;
};

export function AssignConfig({ assignNewNodeConfig, node }: AssignConfigProps) {
  const { t } = useMimicTranslation('node');
  const tooltipMessage = !node.operatingSystem ? t('noSystemProfileInfo') : '';

  return (
    <NodeViewPageCard dataTestId="assignConfig" bodyStyles={{ padding: '0 0 16px 0' }}>
      <div style={{ padding: '40px 0' }}>
        <Flex vertical align="center">
          <NoData fontSize="6em" />
          <Text type="subtle">{t('notConfigured')}</Text>
          <GuardedButton
            requiredRole="superadmin"
            type="primary"
            onClick={() => assignNewNodeConfig()}
            dataTestId="assign-config-button"
            style={{ minHeight: '48px' }}
            disabled={!node.operatingSystem}
          >
            {t('assignConfiguration')}
          </GuardedButton>
          {!node.operatingSystem && (
            <div style={{ display: 'flex', gap: 4, color: 'rgba(161, 53, 23, 1)' }}>
              <ErrorIcon />
              {tooltipMessage}
            </div>
          )}
        </Flex>
      </div>
    </NodeViewPageCard>
  );
}
