/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { useState } from 'react';

import { Node, OperationalStateChangeResp } from '@/client';
import { ConnectivityInfo } from '@/components/Node/ConnectivityInfo';
import { DeflectionsInfo } from '@/components/Node/DeflectionsInfo';
import { DetectionsInfo } from '@/components/Node/DetectionsInfo';
import { IncomingData } from '@/components/Node/IncomingData';
import { NodeTransitionIndicator } from '@/components/Node/NodeTransitionIndicator';
import { useMessage } from '@/hooks/message';
import { useUpdateNodeOperationalStateMutation } from '@/hooks/mutation/useUpdateNodeOperationalStateMutation';
import { useAuthorization } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';
import { Alert } from '@/primitives/Alert';
import { Flex } from '@/primitives/Flex';
import { NodeViewCard } from '@/primitives/NodeViewCard';
import { Space } from '@/primitives/Space';
import { Switch } from '@/primitives/Switch';
import { Text } from '@/primitives/Text';
import { antdTokens } from '@/theme';

export type NodeStateProps = {
  isLoading: boolean;
  node: Node;
};

export function NodeState({ isLoading, node }: NodeStateProps) {
  const { t } = useMimicTranslation('node');

  const [isOperational, setIsOperational] = useState(node.requestedRevisionState?.operationalState === 'active');
  const [message] = useMessage();
  const canManageNodes = useAuthorization('admin');

  const interactionsBlocked = node.requestedRevisionState?.status === 'pending';
  const showError = node.requestedRevisionState?.status === 'rejected';

  const { mutate } = useUpdateNodeOperationalStateMutation({
    onSuccess: () => {
      message.success(t('operationalStateChangeRequested'));
    },
    onError: () => {
      message.error(t('operationalStateChangeFailed'));
    },
  });

  useSubscribeToEvents<OperationalStateChangeResp>('node:operational-state-changed', (event) => {
    if (!event.entityIds.includes(node.id) || !event.data?.data) return;
    const nextIsOperationalState = event.data.data.operationalState === 'active';
    if (nextIsOperationalState === isOperational) return;
    setIsOperational(nextIsOperationalState);
  });

  const onChange: SwitchChangeEventHandler = (checked) => {
    const state = checked ? 'active' : 'idle';

    mutate({
      nodeId: node.id,
      updateOperationalState: { requestedOperationalState: state },
    });
  };

  const backgroundColor = interactionsBlocked ? antdTokens.colorBgContainer : undefined;

  return (
    <Space dir="vertical" size="sm" width="100%">
      <NodeViewCard gap="xxs" style={{ backgroundColor }} bodyStyles={{ paddingTop: '24px' }}>
        <Form layout="vertical">
          <Flex justify="space-between">
            <IncomingData
              disabled={interactionsBlocked}
              marginBottom={0}
              labelMarginBottom="12px"
              label={t('operationalState')}
              loading={isLoading}
              bold
            >
              <Switch
                checked={isOperational}
                disabled={!canManageNodes || interactionsBlocked}
                checkedChildren={t('active')}
                unCheckedChildren={<Text type="subtle">{t('idle')}</Text>}
                onChange={onChange}
                dataTestId="operational-state-switch"
                style={{
                  transform: 'scale(1.5)',
                  transformOrigin: 'left',
                }}
              />
            </IncomingData>
            <ConnectivityInfo
              disabled={interactionsBlocked}
              isLoading={isLoading}
              connectivityState={node.connectivityState}
            />
            <DetectionsInfo disabled={interactionsBlocked} isLoading={isLoading} hallmarksCount={node.hallmarksCount} />
            <DeflectionsInfo
              disabled={interactionsBlocked}
              isLoading={isLoading}
              deflectionsCount={node.deflectionsCount}
            />
          </Flex>
        </Form>
        <NodeTransitionIndicator node={node} />
      </NodeViewCard>

      {showError && (
        <Alert
          dataTestId="operational-state-change-failed-alert"
          message={t('operationalStateChangeFailed')}
          description={t('operationalStateChangeFailedDescription', {
            requestedState: t(node.requestedRevisionState!.operationalState),
          })}
          type="error"
          showIcon
        />
      )}
    </Space>
  );
}
