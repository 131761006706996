/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { CloseOutlined } from '@ant-design/icons';

import { Avatar } from '@/primitives/Avatar';
import { Input } from '@/primitives/Input';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { tokens } from '@/theme';

import './TenantSwitcherInput.css';

export type TenantSwitcherInputProps = {
  inputSearch: string;
  setInputSearch: (val: string) => void;
  handleOnBlur: () => void;
  handlePressEnter: (term: string) => void;
};

export function TenantSwitcherInput({
  inputSearch,
  setInputSearch,
  handleOnBlur,
  handlePressEnter,
}: TenantSwitcherInputProps) {
  const dark = useMgmtPlaneStateStore((state) => state.darkMode);
  const inputClassName = dark ? 'input-dark' : 'input-light';
  const closeIconClassName = dark ? 'close-icon-dark' : 'close-icon-light';

  return (
    <section className="tenant-switcher-input" data-testid="tenant-switcher-input">
      <Input
        className={`tenant-switcher-input-input ${inputClassName}`}
        value={inputSearch}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setInputSearch(e.target.value);
        }}
        autoFocus
        onBlur={handleOnBlur}
        onPressEnter={() => handlePressEnter(inputSearch)}
        allowClear={{
          clearIcon: <CloseOutlined className={closeIconClassName} onClick={() => setInputSearch('')} />,
        }}
        prefix={
          <Avatar
            size="xxs"
            text="-"
            color={tokens.color.gold.gold03}
            backgroundColor={tokens.color.text.textSubtle}
            dataTestId="tenant-avatar"
            shape="square"
          />
        }
      />
    </section>
  );
}
