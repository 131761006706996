/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ReactNode } from 'react';

import { Tooltip } from '@/primitives/Tooltip';

export type WorkspaceBadgeProps = {
  badgeColor: string;
  children?: ReactNode;
  showTooltip?: boolean;
  onClick?: () => void;
  size?: 'medium' | 'large';
};

export function WorkspaceBadge({ badgeColor, children, showTooltip, onClick, size }: WorkspaceBadgeProps) {
  const backgroundColor = badgeColor;
  const tooltipText = showTooltip ? 'Change node workspace' : undefined;
  const fontSize = size === 'large' ? '24px' : '14px';
  const padding = size === 'large' ? '10px 15px' : '5px 10px';

  return (
    <button
      style={{
        backgroundColor,
        margin: 0,
        borderRadius: '5px',
        padding,
        lineHeight: '14px',
        display: 'inline-block',
        fontSize,
        border: 'none',
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
      type="button"
    >
      <Tooltip title={tooltipText}>{children}</Tooltip>
    </button>
  );
}
