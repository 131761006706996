/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useState } from 'react';

import { ApiToken } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedAPITokensQuery } from '@/hooks/query/usePaginatedAPITokensQuery';
import { APITokensHeader } from '@/pages/APITokens/APITokensHeader';
import { APITokensTable } from '@/pages/APITokens/APITokensTable';
import { RevokeAPITokenModal } from '@/pages/APITokens/RevokeAPITokenModal';
import { Container } from '@/primitives/Container';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function APITokensPage() {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [apiToken, setAPIToken] = useState<ApiToken | undefined>(undefined);

  const apiTokensQuery = usePaginatedAPITokensQuery({
    type: 'queryParams',
  });

  if (!tenantID) return null;

  const revokeAPIToken = (token: ApiToken) => {
    setAPIToken(token);
  };

  const closeDeleteDialog = () => {
    setAPIToken(undefined);
  };

  return (
    <ContentContainer width="100%">
      <Container full style={{ width: '100%' }}>
        <APITokensHeader />
        <QueryWrapper queryResult={apiTokensQuery}>
          {(apiTokensData) => (
            <APITokensTable
              apiTokensData={apiTokensData}
              updateQueryParams={apiTokensQuery.updateQueryParams}
              apiTokenFilters={apiTokensQuery.filters}
              revokeAPIToken={revokeAPIToken}
            />
          )}
        </QueryWrapper>

        {apiToken && <RevokeAPITokenModal apiToken={apiToken} onCancelAPITokenRevocation={closeDeleteDialog} />}
      </Container>
    </ContentContainer>
  );
}
