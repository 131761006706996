/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError, GetWorkspaceByIdResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useSubscribeToEvents } from '@/hooks/useSubscribeToEvents';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UseWorkspaceByIdQueryProps = {
  workspaceId: string;
};

/**
 * Custom hook that fetches and subscribes to workspace data updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * Features:
 * - Fetches workspace data by ID
 * - Auto-invalidates query on relevant WebSocket events
 * - Handles tenant context
 * - Type-safe API response handling
 *
 * @param props.workspaceId - Unique identifier of the workspace to fetch
 * @returns React Query result containing workspace data and query state
 *
 * @example
 * ```tsx
 * const { data: workspace, isLoading } = useWorkspaceByIdQuery({ workspaceId: '123' });
 * ```
 */
export function useWorkspaceByIdQuery({ workspaceId }: UseWorkspaceByIdQueryProps) {
  const queryClient = useQueryClient();
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  useSubscribeToEvents(['workspace:updated', 'workspace-membership:updated'], (payload) => {
    if (!payload.entityIds.includes(workspaceId)) return;
    queryClient.invalidateQueries({ queryKey: ['workspaces', selectedTenantId, workspaceId] });
  });

  return useQuery<GetWorkspaceByIdResponse, ApiError>({
    queryKey: ['workspaces', selectedTenantId, workspaceId],
    queryFn: () => {
      return mgmtPlaneAPIClient.workspaces.getWorkspaceById({ id: workspaceId, tenantId: selectedTenantId! });
    },
    enabled: !!selectedTenantId && !!workspaceId,
  });
}
