/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Badge } from '@/primitives/Badge';
import { tokens } from '@/theme';

enum Environments {
  PROD = 'prod',
  UAT = 'uat',
  DEV = 'dev',
  QA = 'qa',
  ARENA = 'arena',
  STAGING = 'staging',
  LOCAL = 'local',
}

function getEnvironmentBadgeDetails(env: string) {
  switch (env) {
    case Environments.PROD:
      return {
        text: '',
        bgColor: '',
        color: '',
      };
    case Environments.UAT:
      return {
        text: 'UAT',
        bgColor: tokens.color.components.badge.primitive.badgeEmphasisPrimarySurface,
        color: tokens.color.components.badge.primitive.badgeEmphasisPrimaryOnSurface,
      };
    case Environments.DEV:
      return {
        text: 'Dev',
        bgColor: tokens.color.components.badge.primitive.badgeEmphasisBlueSurface,
        color: tokens.color.components.badge.primitive.badgeEmphasisBlueOnSurface,
      };
    case Environments.QA:
      return {
        text: 'QA',
        bgColor: tokens.color.components.badge.primitive.badgeEmphasisPurpleSurface,
        color: tokens.color.components.badge.primitive.badgeEmphasisPurpleOnSurface,
      };
    case Environments.ARENA:
      return {
        text: 'Arena',
        bgColor: tokens.color.components.badge.primitive.badgeEmphasisOrangeSurface,
        color: tokens.color.components.badge.primitive.badgeEmphasisOrangeOnSurface,
      };
    case Environments.STAGING:
      return {
        text: 'Staging',
        bgColor: tokens.color.components.badge.primitive.badgeEmphasisGreenSurface,
        color: tokens.color.components.badge.primitive.badgeEmphasisGreenOnSurface,
      };
    default:
      return {
        text: 'Local',
        bgColor: tokens.color.components.badge.primitive.badgeSubtleNeutralSurface,
        color: tokens.color.components.badge.primitive.badgeSubtleNeutralOnSurface,
      };
  }
}

export type EnvironmentBadgeProps = {
  environment: string;
};

export function EnvironmentBadge({ environment }: EnvironmentBadgeProps) {
  if (environment === 'prod') return null;

  const envDetails = getEnvironmentBadgeDetails(environment);

  return (
    <Badge
      dataTestId="env-badge"
      bgColor={envDetails.bgColor}
      text={envDetails.text}
      borderRadius="2px"
      minHeight="22px"
      size="lg"
      style={{
        borderColor: envDetails.bgColor,
        color: envDetails.color,
        fontWeight: 400,
        fontSize: '0.875rem',
      }}
    />
  );
}
