/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ColumnsType } from 'antd/es/table';

import { Workspace, GetWorkspaceMembersResponse, UpdateWorkspaceMembership, User } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useMessage } from '@/hooks/message';
import { useUpdateWorkspaceMembership } from '@/hooks/mutation/useUpdateWorkspaceMembership';
import { usePaginatedWorkspaceMembersQuery } from '@/hooks/query/usePaginatedWorkspaceMembersQuery';
import { Container } from '@/primitives/Container';
import { Select } from '@/primitives/Select';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type WorkspaceMembersProps = {
  workspace: Workspace;
};

export function WorkspaceMembers({ workspace }: WorkspaceMembersProps) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const workspaceMembersQuery = usePaginatedWorkspaceMembersQuery({ params: { id: workspace.id } });
  const [message] = useMessage();

  const { mutate } = useUpdateWorkspaceMembership({
    onError: () => {
      message.error('Unable to update workspace membership');
    },
  });

  const onRoleChange = (userId: string, role: UpdateWorkspaceMembership['role']) => {
    mutate({ id: workspace.id, userId, tenantId: tenantId!, requestBody: { role } });
  };

  const activeUsersColumns: ColumnsType<User> = [
    {
      title: 'Member',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role: User['role'], user) => {
        return (
          <Select
            defaultValue={role}
            style={{ width: 120 }}
            onChange={(value) => {
              onRoleChange(user.id, value as UpdateWorkspaceMembership['role']);
            }}
            options={[
              { value: 'admin', label: 'Admin' },
              { value: 'editor', label: 'Editor' },
              { value: 'viewer', label: 'Viewer' },
              { value: 'none', label: 'No access' },
            ]}
          />
        );
      },
    },
  ];

  return (
    <Container style={{ marginTop: '24px' }}>
      <QueryWrapper<GetWorkspaceMembersResponse> queryResult={workspaceMembersQuery}>
        {(data) => (
          <PaginatedTable
            data={data}
            columns={activeUsersColumns}
            rowKey={(user: User) => user.id}
            style={{ width: '100%' }}
          />
        )}
      </QueryWrapper>
    </Container>
  );
}
