/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Node, NodeFilters, PaginatedNodesResponse, Tag } from '@/client';
import { PaginatedTable } from '@/components/PaginatedTable';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { getNodesColumns, NodesColumns } from '@/pages/Nodes/getNodesColumns';
import { TableProps } from '@/primitives/Table';
import { UpdateParams } from '@/utils/params';

import { ExpandedRow } from './ExpandedRow';

export type NodesTableProps = {
  tenantID: string;
  nodesData: PaginatedNodesResponse;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
  columns: NodesColumns[];
  tags?: Tag[];
  onAssignConfiguration?: (node: Node) => void;
  getNodeConfigurationPath: (nodeConfigId: string) => string;
  rowSelection?: TableProps<Node>['rowSelection'];
  selectedNodeIds?: string[];
  primaryAction?: React.ReactNode;
  noSystemProfileInfoMessage?: string;
  selectionWarning?: string;
  selectionError?: string;
  hideFilters?: boolean;
};

const expandedRowRender = (record: Node) => <ExpandedRow node={record} />;

export function NodesTable({
  nodesData,
  onParamsChange,
  columns,
  tags,
  tenantID,
  onAssignConfiguration,
  getNodeConfigurationPath,
  rowSelection,
  selectedNodeIds,
  primaryAction,
  noSystemProfileInfoMessage,
  selectionWarning,
  selectionError,
  hideFilters,
}: NodesTableProps) {
  const { t } = useMimicTranslation('nodes');
  const { filters } = nodesData.meta;

  const columnsShown = getNodesColumns(
    columns,
    tenantID,
    t,
    getNodeConfigurationPath,
    tags,
    filters,
    onAssignConfiguration,
    hideFilters,
  );

  return (
    <PaginatedTable
      data-testid="nodes-table"
      rowKey="id"
      data={nodesData}
      columns={columnsShown}
      onParamsChange={onParamsChange}
      rowSelection={rowSelection}
      selectedIds={selectedNodeIds}
      primaryAction={primaryAction}
      selectionWarning={selectionWarning}
      selectionError={noSystemProfileInfoMessage || selectionError}
      expandable={{
        expandedRowRender,
        rowExpandable: () => true,
      }}
    />
  );
}
