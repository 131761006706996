/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ConfigRevision, Node, NodeFilters } from '@/client';
import { ConfigWizardOverlay } from '@/components/ConfigWizardOverlay/ConfigWizardOverlay';
import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useMessage } from '@/hooks/message';
import { useAssignConfigRevisionMutation } from '@/hooks/mutation/useAssignConfigRevisionMutation';
import { usePaginatedNodesQuery } from '@/hooks/query/usePaginatedNodesQuery';
import { usePaginatedTagsQuery } from '@/hooks/query/usePaginatedTagsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NodeFilter, NodesHeader } from '@/pages/Nodes/NodesHeader';
import { NodesList } from '@/pages/Nodes/NodesList';
import { Container } from '@/primitives/Container';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { UpdateParams } from '@/utils/params';

export function NodesPage() {
  const [message] = useMessage();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useMimicTranslation('node');
  const [selectedNode, setSelectedNode] = useState<Node | undefined>();
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const configuredNodesQuery = usePaginatedNodesQuery({
    type: 'queryParams',
    params: { filters: { isOperating: true } },
    urlPrefix: 'configured',
  });

  const pendingNodesQuery = usePaginatedNodesQuery({
    type: 'queryParams',
    params: { filters: { isOperating: false }, sort: ['-dateCreated'] },
    urlPrefix: 'pending',
  });

  const tagsQuery = usePaginatedTagsQuery({});

  const showPending = searchParams.get('showPending') === 'true';
  const nodesQuery = showPending ? pendingNodesQuery : configuredNodesQuery;
  const nodeState = showPending ? NodeFilter.PENDING : NodeFilter.CONFIGURED;

  const updateQueryParams: UpdateParams<Node, NodeFilters> = (params) => {
    const isOperating = !showPending;
    const updateCallback = isOperating ? configuredNodesQuery.updateQueryParams : pendingNodesQuery.updateQueryParams;

    updateCallback({
      ...params,
      filters: {
        ...params.filters,
        isOperating,
      },
    });
  };

  const toggleState = (state: NodeFilter) => {
    const isOperating = state === NodeFilter.CONFIGURED;
    const updateCallback = isOperating ? configuredNodesQuery.updateQueryParams : pendingNodesQuery.updateQueryParams;
    updateCallback({ filters: { isOperating } });

    setSearchParams((prev) => {
      prev.set('showPending', isOperating ? 'false' : 'true');
      return prev;
    });
  };

  const configuredCount = configuredNodesQuery.data?.meta.page.totalItems;
  const pendingCount = pendingNodesQuery.data?.meta.page.totalItems;

  const onAssignConfiguration = (node: Node) => {
    setSelectedNode(node);
  };

  const { mutate: assignConfigRevision } = useAssignConfigRevisionMutation({
    onError: (error) => {
      message.error(t('feedback.assignNodeConfigError'));
      Sentry.captureException(error);
    },
  });

  const onAssign = (nodeToAssign: Node, configRevision: ConfigRevision) => {
    assignConfigRevision({
      nodeIDs: [nodeToAssign.id],
      configID: configRevision.nodeConfigId!,
      revisionNumber: configRevision.revisionNumber,
    });

    setSelectedNode(undefined);
  };

  const onCancel = () => {
    setSelectedNode(undefined);
  };

  const getNodeConfigurationPath = (nodeConfigId: string) =>
    `/tenants/${selectedTenantID}/node-configs/${nodeConfigId}`;

  return (
    <>
      <ContentContainer width="100%">
        <Container>
          <NodesHeader
            nodesState={nodeState}
            setNodeState={toggleState}
            configuredCount={configuredCount}
            pendingCount={pendingCount}
            configuredNodesIsLoading={configuredNodesQuery.isLoading}
            pendingNodesIsLoading={pendingNodesQuery.isLoading}
          />
          <QueryWrapper queryResult={tagsQuery}>
            {(tagsData) => (
              <QueryWrapper queryResult={nodesQuery}>
                {(nodesData) => (
                  <NodesList
                    tenantID={selectedTenantID!}
                    tags={tagsData.data}
                    onParamsChange={updateQueryParams}
                    nodesState={nodeState}
                    nodesData={nodesData}
                    onAssignConfiguration={onAssignConfiguration}
                    getNodeConfigurationPath={getNodeConfigurationPath}
                  />
                )}
              </QueryWrapper>
            )}
          </QueryWrapper>
        </Container>
      </ContentContainer>
      <ConfigWizardOverlay node={selectedNode} onCancel={onCancel} onAssign={onAssign} />
    </>
  );
}
