/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { CreateAPITokenModal } from '@/pages/APITokens/CreateAPITokenModal';
import { Container } from '@/primitives/Container';

export function APITokensHeader() {
  const [message] = useMessage();
  const { t } = useMimicTranslation('apiTokens');

  const [newAPITokenOpen, setNewAPITokenOpen] = useState<boolean>();

  const onCancelAPITokenCreation = () => {
    setNewAPITokenOpen(false);
  };

  const copyTokenToClipboard = async (text: string) => {
    navigator.clipboard.writeText(text).catch(() => {
      message.error(t('failedCopy'));
    });

    await message.info(t('tokenCopied'));
  };

  return (
    <PageHeader title={t('listTitle')} level={2}>
      <Container horizontal gap="sm">
        <GuardedButton
          requiredRole="admin"
          type="primary"
          dataTestId="create-api-token"
          onClick={() => setNewAPITokenOpen(!newAPITokenOpen)}
        >
          <PlusOutlined /> {t('newAPIToken')}
        </GuardedButton>
      </Container>
      {newAPITokenOpen && (
        <CreateAPITokenModal
          onCancelAPITokenCreation={onCancelAPITokenCreation}
          copyToClipboard={copyTokenToClipboard}
        />
      )}
    </PageHeader>
  );
}
