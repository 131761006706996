/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, CreateWorkspace, WorkspaceResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type NewWorkspaceMutationConfig = {
  onSuccess?: (data: WorkspaceResponse, variables: CreateWorkspace) => void;
  onError?: (error: unknown, variables: CreateWorkspace) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: CreateWorkspace) => Promise<unknown> | void;
};

/**
 * Hook for creating new workspace through the management plane API.
 *
 * @param config.name - String of the name of the new workspace
 * @param config.onSuccess - Called after successful workspace creation
 * @param config.onError - Called if workspace creation fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for creating new workspace
 *
 * @example
 * ```tsx
  const { mutate } = useNewWorkspaceMutation({
 *   onSuccess: (data) => console.log('Workspace Created:', data),
 *   onError: (error) => console.error('Workspace Created failed:', error)
    },
  });

   * // Create Workspace
 * mutate({ name: 'test-workspace-0001', badgeColor: '#cccccc' });
 * ```
 */

export function useNewWorkspaceMutation({ onSuccess, onError, onSettled, onMutate }: NewWorkspaceMutationConfig) {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<WorkspaceResponse, ApiError, CreateWorkspace>({
    mutationFn: (newWorkspace: CreateWorkspace) => {
      return mgmtPlaneAPIClient.workspaces.createWorkspace({ tenantId: tenantID!, requestBody: newWorkspace });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
