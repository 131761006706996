/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, UpdateWorkspaceMembershipData, UpdateWorkspaceMembershipResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UpdateUserMutationConfig = {
  onSuccess?: (data: UpdateWorkspaceMembershipResponse, variables: UpdateWorkspaceMembershipData) => void;
  onError?: (error: unknown, variables: UpdateWorkspaceMembershipData) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: UpdateWorkspaceMembershipData) => void;
};

/**
 * Hook for updating workspace membership.
 *
 * @param config.onSuccess - Called after successful user update
 * @param config.onError - Called if update fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for updating workspace membership
 *
 * @example
 * ```tsx
 * const { mutate } = useUpdateWorkspaceMembership({
 *   onSuccess: (data) => console.log('WorkspaceMembership updated:', data),
 *   onError: (error) => console.error('WorkspaceMembership update failed:', error)
 * });
 *
 * // Update workspace membership
 * mutate({ userId: '9fb763f7-33de-4969-a7a0-b808f4637318', role: 'admin' });
 * ```
 */

export function useUpdateWorkspaceMembership({ onSuccess, onError, onSettled, onMutate }: UpdateUserMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<
    UpdateWorkspaceMembershipResponse,
    ApiError,
    UpdateWorkspaceMembershipData
  >({
    mutationFn: ({ id, userId, requestBody }) => {
      return mgmtPlaneAPIClient.workspaces.updateWorkspaceMembership({
        id,
        userId,
        requestBody,
        tenantId: tenantId!,
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
