/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NewWorkspaceDialog } from '@/pages/Workspaces/NewWorkspaceDialog';
import { Container } from '@/primitives/Container';
import { Dropdown } from '@/primitives/Dropdown';

export function WorkspacesHeader() {
  const { t } = useMimicTranslation('workspaces');

  const [newWorkspaceOpen, setNewWorkspaceOpen] = useState<boolean>();

  const onNewWorkspaceOpenChange = () => {
    if (newWorkspaceOpen) {
      // Allows background clicks to close dropdown
      setNewWorkspaceOpen(undefined);
    }
  };

  const newWorkspaceDialog = () => <NewWorkspaceDialog setNewWorkspaceOpen={setNewWorkspaceOpen} />;

  return (
    <PageHeader title={t('title')} text={t('subtitle')} level={2}>
      <Container horizontal gap="sm">
        <Dropdown open={newWorkspaceOpen} onOpenChange={onNewWorkspaceOpenChange} customDialog={newWorkspaceDialog}>
          <GuardedButton
            requiredRole="editor"
            type="primary"
            dataTestId="new-workspace"
            onClick={() => setNewWorkspaceOpen(!newWorkspaceOpen)}
          >
            <PlusOutlined /> {t('newWorkspace')}
          </GuardedButton>
        </Dropdown>
      </Container>
    </PageHeader>
  );
}
