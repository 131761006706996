/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { NotificationParamsSchema } from 'openapi-spec/api/routes/notifications/schemas';

import {
  GetNotificationsData,
  GetNotificationsResponse,
  Notification,
  NotificationFilters,
  NotificationSortableFields,
} from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedNotificationsQueryParams = {
  params?: Omit<GetNotificationsData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated notifications with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated notifications response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedNotificationsQuery({ params, type }: UsePaginatedNotificationsQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<
    GetNotificationsResponse,
    Notification,
    NotificationFilters,
    NotificationSortableFields,
    GetNotificationsData
  >({
    apiCall: mgmtPlaneAPIClient.notifications.getNotifications.bind(mgmtPlaneAPIClient.notifications),
    filterTypes: zodSchemaToTypeMap(NotificationParamsSchema.shape),
    queryKey: 'notifications',
    realtimeEvents: ['notification:created', 'node:connectivity-updated', 'workspace-membership:updated'],
    params: {
      tenantId: selectedTenantId!,
      ...params,
    },
    type,
  });
}
