/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useMutation } from '@tanstack/react-query';

import { ClearHallmarks, ClearHallmarksResponseSchema } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type ClearHallmarksMutationConfig = {
  onSuccess?: (data: ClearHallmarksResponseSchema, variables: ClearHallmarks) => void;
  onError?: (error: unknown, variables: ClearHallmarks) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

/**
 * Hook for clearing hallmarks by node ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful clearing of hallmarks
 * @param config.onError - Called if clearing fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for clearing hallmarks
 *
 * @example
 * ```tsx
 * const { mutate } = useClearHallmarksMutation({
 *   onSuccess: (data) => console.log('Hallmarks cleared:', data),
 *   onError: (error) => console.error('Clearing hallmarks failed:', error)
 * });
 *
 * // Clear Hallmarks
 * mutate({ reason: 'some reason here' });
 * ```
 */

export function useClearHallmarksMutation(nodeId: string, config?: ClearHallmarksMutationConfig) {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return useMutation<ClearHallmarksResponseSchema, unknown, ClearHallmarks>({
    mutationFn: (form: ClearHallmarks) => {
      return mgmtPlaneAPIClient.nodes.clearHallmarks({ tenantId: tenantID!, nodeId, requestBody: form });
    },
    onSuccess: config?.onSuccess,
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
