/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { MimicErrorBoundary } from '@/components/MimicErrorBoundary';
import { useMessage } from '@/hooks/message';
import { Theme } from '@/primitives/Theme';
import '@/utils/i18n';

import './theme/tokens.css';

dayjs.extend(utc);

// define the mimicConfig object so Typescript doesn't complain
declare global {
  interface Window {
    mimicConfig: {
      [key: string]: string | number | boolean;
    };
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export type MimicAppProps = {
  children: React.ReactNode;
};

export function MimicApp({ children }: MimicAppProps) {
  const [, messageContext] = useMessage();
  const [, bottomMessageContext] = useMessage({ namespace: 'bottom' });
  return (
    <MimicErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Theme>
          {children}
          {messageContext}
          {bottomMessageContext}
        </Theme>
      </QueryClientProvider>
    </MimicErrorBoundary>
  );
}
