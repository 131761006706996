/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ConfigRevision, NodeConfig } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { PageHeader } from '@/components/PageHeader';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedNodeConfigsQuery } from '@/hooks/query/usePaginatedNodeConfigsQuery';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NodeConfigsList } from '@/pages/Configs/NodeConfigsList';
import { Container } from '@/primitives/Container';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function ConfigsPage() {
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const { t } = useMimicTranslation('nodeConfigurations');

  const configsQuery = usePaginatedNodeConfigsQuery({ type: 'queryParams' });

  const nodeConfigPath = (nodeConfig: NodeConfig) => `/tenants/${selectedTenantID}/node-configs/${nodeConfig.id}`;
  const getNodeConfigurationRevisionPath = (nodeConfigRevision: ConfigRevision) =>
    `/tenants/${selectedTenantID}/node-configs/${nodeConfigRevision.nodeConfigId}/revisions/${nodeConfigRevision.revisionNumber}`;

  return (
    <ContentContainer width="100%">
      <Container>
        <PageHeader title={t('title')} level={2} />
        <QueryWrapper queryResult={configsQuery}>
          {(configsData) => (
            <NodeConfigsList
              tags={[]}
              onParamsChange={configsQuery.updateQueryParams}
              configsData={configsData}
              getNodeConfigPath={nodeConfigPath}
              getNodeConfigurationRevisionPath={getNodeConfigurationRevisionPath}
            />
          )}
        </QueryWrapper>
      </Container>
    </ContentContainer>
  );
}
