/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useDebounce } from '@uidotdev/usehooks';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';

import { Tenant } from '@/client';
import { TenantSwitcherInput } from '@/components/TenantSwitcher/TenantSwitcherInput';
import { TenantSwitcherSelectedTenant } from '@/components/TenantSwitcher/TenantSwitcherSelectedTenant';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

import './TenantSwitcherOption.css';

export type TenantSwitcherOptionProps = {
  selectedTenant?: Tenant;
  updateSearchTerm: (searchTerm: string) => void;
  showInput: boolean;
  setShowInput: (showInput: boolean) => void;
  handlePressEnter: (term: string) => void;
  inputSearch: string;
  setInputSearch: (val: string) => void;
};

export function TenantSwitcherOption({
  selectedTenant,
  updateSearchTerm,
  showInput,
  setShowInput,
  handlePressEnter,
  inputSearch,
  setInputSearch,
}: TenantSwitcherOptionProps) {
  const debouncedSearchTerm = useDebounce(inputSearch, 300);
  const [nameVisible, setNameVisible] = useState(false);
  const dark = useMgmtPlaneStateStore((state) => state.darkMode);
  const className = dark ? 'dark' : 'light';
  const isSwitchingApps = useRef(false); // Value to prevent blur action if app is switched

  useEffect(() => {
    const handleVisibilityChange = () => {
      isSwitchingApps.current = document.hidden;
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleOnBlur = () => {
    if (isSwitchingApps.current) return; // Prevent blur action if app is switched
    setShowInput(false);
  };

  const toggleNameVisible: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setNameVisible(!nameVisible);
  };

  useEffect(() => {
    updateSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm, updateSearchTerm]);

  const classes = ['tenant-switcher-option', className];

  if (showInput) {
    if (dark) classes.push('show-input-dark');
    if (!dark) classes.push('show-input-light');
  }

  return (
    <section className={classes.join(' ')}>
      {!showInput && (
        <TenantSwitcherSelectedTenant
          selectedTenant={selectedTenant!}
          nameVisible={nameVisible}
          setShowInput={setShowInput}
          toggleNameVisible={toggleNameVisible}
        />
      )}
      {showInput && (
        <TenantSwitcherInput
          inputSearch={inputSearch}
          setInputSearch={setInputSearch}
          handleOnBlur={handleOnBlur}
          handlePressEnter={handlePressEnter}
        />
      )}
    </section>
  );
}
