/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Modal } from 'antd';

import { Workspace } from '@/client';
import { useMessage } from '@/hooks/message';
import { useDeleteWorkspaceMutation } from '@/hooks/mutation/useDeleteWorkspaceMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';

type DeleteWorkspaceModalProps = {
  deleteWorkspace: Workspace | undefined;
  deleteIsPending: boolean;
  setDeleteIsPending: (isPending: boolean) => void;
  onCancelWorkspaceDelete: () => void;
};

export function DeleteWorkspaceModal({
  deleteWorkspace,
  deleteIsPending,
  setDeleteIsPending,
  onCancelWorkspaceDelete,
}: DeleteWorkspaceModalProps) {
  const { t } = useMimicTranslation('workspaces');

  const [message] = useMessage();

  const { mutate: deleteWorkspaceMutation } = useDeleteWorkspaceMutation({
    onError: (_, workspace) => {
      message.error(t('workspaceDeleteError', workspace));
      setDeleteIsPending(false);
    },
    onSuccess: (_, deletedWorkspace) => {
      message.success(t('workspaceDeleted', deletedWorkspace));
      closeDeleteDialog();
      setDeleteIsPending(false);
    },
    onMutate: () => {
      setDeleteIsPending(true);
    },
  });

  const onWorkspaceDelete = () => {
    deleteWorkspaceMutation(deleteWorkspace!);
  };

  const closeDeleteDialog = () => {
    onCancelWorkspaceDelete();
  };

  return (
    <Modal
      open={Boolean(deleteWorkspace)}
      onCancel={onCancelWorkspaceDelete}
      onOk={onWorkspaceDelete}
      cancelText={t('confirmDelete.cancel')}
      cancelButtonProps={{ disabled: deleteIsPending }}
      okText={t('confirmDelete.ok')}
      okButtonProps={{ danger: true, loading: deleteIsPending }}
    >
      <Container padding="sm">
        <Title level={4}>{t('confirmDelete.title', { name: deleteWorkspace?.name })}</Title>
        <Text>{t('confirmDelete.body1')}</Text>
      </Container>
    </Modal>
  );
}
